export enum AccountTab {
  HOME = 'consumer_home',
  SHARE = 'consumer_share',
  SHOT = 'consumer_shot',
  EVENTS = 'consumer_events',
  RESERVE = 'consumer_reserve',
  MY_PAGE = 'consumer_my_page',
  MEMBERS = 'consumer_members'
}

export enum AccountMySchedulesTabEnum {
  ATTENDING = 0,
  ATTENDED = 1
}

export enum RegisterAccountStepEnum {
  SUMMARY = 1,
  INPUT = 2,
  AUTHORIZATION = 3,
  COMPLETION = 4
}

export enum SignUpInTabEnum {
  SIGN_IN = 0,
  SIGN_UP = 1
}

export enum SignUpInSendEmailStatus {
  SIGN_UP = 'sign-up',
  RESET_PASSWORD = 'reset-password'
}

export enum MyPageTabEnum {
  OVERVIEW = 0,
  USAGE_INFO = 1,
  ENTER_LEAVE_HISTORY = 2
}
