import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Process } from '../../enums/action-enum';
import { toProcessAction } from '../actions/process-action';
import store from '../store';

interface P {
  process: Process;
  saveProcess: (process: Process) => void;
  clearProcess: () => void;
}

function useProcess(): P {
  const unmountRef = useUnmountRef();
  const [process, setProcess] = useSafeState<Process>(unmountRef, store.getState().process);

  const saveProcess = useSafeCallback(
    (process: Process): void => {
      setProcess(process);
      const action = toProcessAction(process);
      store.dispatch(action);
    },
    [setProcess]
  );

  const clearProcess = useSafeCallback((): void => {
    saveProcess(Process.INITIAL);
  }, [saveProcess]);

  return {
    process,
    saveProcess,
    clearProcess
  };
}

export default useProcess;
