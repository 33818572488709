export enum ContractDetailTabNameEnum {
  DETAIL = '契約内容',
  BILLING = '請求',
  MEMBER = 'メンバー',
  LOGS = '履歴'
}

export enum ContractDetailPathEnum {
  DETAIL = 'detail',
  BILLING = 'billing',
  MEMBER = 'member',
  LOGS = 'logs'
}

export enum UsedContractItemCodeEnum {
  ADDRESS_USE = 'address_use',
  LOCKER_USE = 'locker_use'
}

export enum UsedContractUnitCodeEnum {
  MONTH = 'month',
  PEOPLE = 'people',
  PIECE = 'piece',
  YEN = 'yen'
}

export enum UsedContractOptionEnum {
  BASIC_CHARGE = 'basic_charge',
  LOCKER_USE = 'locker_use',
  ADDRESS_USE = 'address_use'
}

export enum ContractDetailInputKeyEnum {
  ITEM_NAME = 'itemName',
  UNIT_PRICE = 'unitPrice',
  QUANTITY = 'quantity',
  UNIT_ID = 'unitId',
  TAX_ID = 'taxId',
  TAX_DIV = 'taxDiv',
  TAX_RATE = 'taxRate',
  DISABLED = 'disabled'
}

export enum ContractDiscountInputKeyEnum {
  ITEM_NAME = 'itemName',
  UNIT_PRICE = 'unitPrice',
  RECURRENCE_COUNT = 'recurrenceCount',
  TAX_ID = 'taxId',
  TAX_DIV = 'taxDiv',
  TAX_RATE = 'taxRate',
  DISABLED = 'disabled'
}

export enum ContractUsedUnitCodeEnum {
  MONTH = 'month',
  PEOPLE = 'people',
  PIECE = 'piece',
  YEN = 'yen'
}

export enum FixedContractOptionEnum {
  BASIC_CHARGE = 'basic_charge',
  LOCKER_USAGE = 'locker_usage',
  ADDRESS_USAGE = 'address_usage'
}

export enum ContractAction {
  OPERATE_ALL = 'operate_all',
  OPEN_SELECTED = 'open_selected'
}
