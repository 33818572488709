/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexTextAllOfStyle = typeof FlexTextAllOfStyle[keyof typeof FlexTextAllOfStyle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexTextAllOfStyle = {
  normal: 'normal',
  italic: 'italic',
} as const;
