export const MEMBER_ONLY = 'こちらは会員様向けの\nコンテンツとなっております。';

export const NON_MEMBER_ONLY = 'こちらは非会員様用の\n入館ページとなっております。';

export const NO_PRIVILEGE = 'アクセスする権限がありません。\n管理者にお問い合わせください。';

export const NO_BASE = '拠点情報が取得できませんでした。\nURLをご確認ください。';

export const NO_VISITOR = 'お客様情報が取得できませんでした。\nQRコードを再度読み取りください。';

export const IS_PAST_DATE = '古い日付が入力されています。';

export const SPACE_UNAVAILABLE_DATE = '予約可能な時間がありません。';
