/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexBoxAllOfJustifyContent = typeof FlexBoxAllOfJustifyContent[keyof typeof FlexBoxAllOfJustifyContent];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexBoxAllOfJustifyContent = {
  center: 'center',
  'flex-start': 'flex-start',
  'flex-end': 'flex-end',
  'space-between': 'space-between',
  'space-around': 'space-around',
  'space-evenly': 'space-evenly',
} as const;
