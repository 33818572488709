import { UnitV2 } from '@atomica.co/irori';
import { Option } from '@atomica.co/types';
import { hasLength } from '@atomica.co/utils';
import { Labels } from '../models/common-model';

export const toUnitOptions = (units: UnitV2[]): Option[] => {
  if (!hasLength(units)) return [];
  return units.map(unit => unit.unitId);
};

export const toUnitLabels = (units: UnitV2[]): Labels => {
  if (!hasLength(units)) return {};
  const labels: Labels = {};
  for (const unit of units) {
    labels[unit.unitId] = unit.unitName;
  }
  return labels;
};
