import {
  ContractPaymentMethod,
  ContractStatus,
  ContractStatusV2,
  InflowSource,
  PaymentMethod,
  PaymentOption,
  PaymentType,
  UseAddress
} from '@atomica.co/irori';
import { Labels } from '../models/common-model';

/** @deprecated */
export const CONTRACT_STATUS_LABELS: Labels = {
  [ContractStatus.VALID]: '有効',
  [ContractStatus.INVALID]: '無効'
};

export const CONTRACT_STATUS_V2_LABELS: Labels = {
  [ContractStatusV2.DRAFT]: '下書き',
  [ContractStatusV2.CONFIRMED]: '有効',
  [ContractStatusV2.DELETED]: '無効'
};

/** @deprecated */
export const CONTRACT_PAYMENT_METHOD_LABELS: Labels = {
  [ContractPaymentMethod.CREDIT_CARD]: 'クレジットカード',
  [ContractPaymentMethod.BANK_TRANSFER]: '銀行振り込み',
  [ContractPaymentMethod.CASH]: '現金'
};

export const CONTRACT_PAYMENT_METHOD_V2_LABELS: Labels = {
  [PaymentMethod.CREDIT_CARD]: 'クレジットカード',
  [PaymentMethod.BANK_TRANSFER]: '銀行振り込み',
  [PaymentMethod.INVOICE]: '請求書',
  [PaymentMethod.CASH]: '現金'
};

/** @deprecated */
export const CONTRACT_PAYMENT_TYPE_LABELS: Labels = {
  [PaymentType.SINGLE]: '一括',
  [PaymentType.INSTALLMENTS]: '分割'
};

export const CONTRACT_PAYMENT_OPTION_V2_LABELS: Labels = {
  [PaymentOption.SINGLE]: '一括',
  [PaymentOption.INSTALLMENT]: '分割'
};

export const CONTRACT_USE_ADDRESS_LABELS: Labels = {
  [UseAddress.NO]: 'なし',
  [UseAddress.YES]: 'あり'
};

export const CONTRACT_INFLOW_SOURCE_LABELS: Labels = {
  [InflowSource.DIRECT]: '直営業',
  [InflowSource.ORGANIC]: '自然流入',
  [InflowSource.SHOT]: 'ショット会員'
};
