/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SaveUserBodyProvidersItemProviderId = typeof SaveUserBodyProvidersItemProviderId[keyof typeof SaveUserBodyProvidersItemProviderId];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaveUserBodyProvidersItemProviderId = {
  email: 'email',
  google: 'google',
  line: 'line',
  sakura: 'sakura',
} as const;
