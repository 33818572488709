/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchContractsV2ForAdminSort = typeof SearchContractsV2ForAdminSort[keyof typeof SearchContractsV2ForAdminSort];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchContractsV2ForAdminSort = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;
