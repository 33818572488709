import { MessageType, MessageV2, SendingStatus } from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import {
  COMMON_MESSAGE_SENDING_STATUS_LABELS,
  ENTRY_FORM_MESSAGE_SENDING_STATUS_LABELS,
  REMIND_MESSAGE_SENDING_STATUS_LABELS
} from '../texts/messages-text';

export const messageToLabel = (message: MessageV2 | undefined, messageType: MessageType): Label => {
  if (!message) {
    switch (messageType) {
      case MessageType.ENTRY:
        return '送信なし';
      case MessageType.REMIND:
        return '通知なし';
      default:
        return COMMON_MESSAGE_SENDING_STATUS_LABELS[SendingStatus.ERROR];
    }
  }

  switch (messageType) {
    case MessageType.ENTRY:
      return ENTRY_FORM_MESSAGE_SENDING_STATUS_LABELS[message.status];
    case MessageType.REMIND:
      return REMIND_MESSAGE_SENDING_STATUS_LABELS[message.status];
    default:
      return COMMON_MESSAGE_SENDING_STATUS_LABELS[SendingStatus.ERROR];
  }
};
