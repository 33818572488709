/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractDtoContractStatus = typeof ContractDtoContractStatus[keyof typeof ContractDtoContractStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractDtoContractStatus = {
  valid: 'valid',
  invalid: 'invalid',
} as const;
