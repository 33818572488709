/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexBubbleAllOfDirection = typeof FlexBubbleAllOfDirection[keyof typeof FlexBubbleAllOfDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexBubbleAllOfDirection = {
  ltr: 'ltr',
  rtl: 'rtl',
} as const;
