/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

/**
 * Reference for offsetTop, offsetBottom, offsetStart, and offsetEnd. Specify one of the following values:

`relative`: Use the previous box as reference.
`absolute`: Use the top left of parent element as reference. The default value is relative.

 */
export type FlexImageAllOfPosition = typeof FlexImageAllOfPosition[keyof typeof FlexImageAllOfPosition];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexImageAllOfPosition = {
  relative: 'relative',
  absolute: 'absolute',
} as const;
