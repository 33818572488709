/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchContractLogsV2ForAdminSort = typeof FetchContractLogsV2ForAdminSort[keyof typeof FetchContractLogsV2ForAdminSort];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchContractLogsV2ForAdminSort = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;
