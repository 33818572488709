/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractDiscountV2DtoQuantityInputType = typeof ContractDiscountV2DtoQuantityInputType[keyof typeof ContractDiscountV2DtoQuantityInputType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractDiscountV2DtoQuantityInputType = {
  manual: 'manual',
  fixed: 'fixed',
  number_of_users: 'number_of_users',
  count_of_selected_options: 'count_of_selected_options',
} as const;
