import { USER_DETAIL_FEATURES, UserAction, UserDetailFeature } from '../enums/users-enum';
import { Path } from '../router/Routes';

export const USER_ACTION_OPTIONS = Object.values(UserAction).filter(action => action !== UserAction.OPERATE_ALL);

export const USER_DETAIL_FEATURE_PATH: Map<UserDetailFeature, Path> = new Map<UserDetailFeature, Path>([
  [USER_DETAIL_FEATURES.OVERVIEW, Path.USER_OVERVIEW],
  [USER_DETAIL_FEATURES.POINT_USAGE_LOG, Path.USER_POINT_USAGE_LOG],
  [USER_DETAIL_FEATURES.ROOM_ENTRY_EXIT_HISTORY, Path.USER_ROOM_ENTRY_EXIT_HISTORY]
]);
