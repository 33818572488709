/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ScheduledLineMessageDtoSendStatus = typeof ScheduledLineMessageDtoSendStatus[keyof typeof ScheduledLineMessageDtoSendStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScheduledLineMessageDtoSendStatus = {
  suspended: 'suspended',
  waiting: 'waiting',
  completed: 'completed',
  retry: 'retry',
  error: 'error',
  not_sent: 'not_sent',
} as const;
