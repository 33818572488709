/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceDtoDefaultOwner = typeof SpaceDtoDefaultOwner[keyof typeof SpaceDtoDefaultOwner];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceDtoDefaultOwner = {
  own: 'own',
  none: 'none',
} as const;
