/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type UserMappingDtoProviderId = typeof UserMappingDtoProviderId[keyof typeof UserMappingDtoProviderId];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserMappingDtoProviderId = {
  email: 'email',
  google: 'google',
  line: 'line',
  sakura: 'sakura',
} as const;
