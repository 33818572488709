/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractV2DtoContractStatus = typeof ContractV2DtoContractStatus[keyof typeof ContractV2DtoContractStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractV2DtoContractStatus = {
  draft: 'draft',
  confirmed: 'confirmed',
  deleted: 'deleted',
} as const;
