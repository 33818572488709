/** @deprecated */
export enum ShotPaymentMethod {
  CASH = 'cash',
  CREDIT_CARD = 'credit_card',
  E_MONEY = 'e_money',
  QR = 'qr',
  ID = 'id',
  QUICK_PAY = 'quick_pay',
  OTHERS = 'others'
}

/** @deprecated */
export enum CoBaTakamatsuShotPaymentMethod {
  CASH = 'cash',
  CREDIT_CARD = 'credit_card',
  E_MONEY = 'e_money',
  PAY_PAY = 'pay_pay',
  ID = 'id',
  OTHERS = 'others'
}
