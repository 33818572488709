/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchResourceForAdmin200Category = typeof FetchResourceForAdmin200Category[keyof typeof FetchResourceForAdmin200Category];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchResourceForAdmin200Category = {
  entrance: 'entrance',
  conference: 'conference',
  device: 'device',
} as const;
