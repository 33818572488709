import { Text } from '@atomica.co/types';
import { IReplaceable } from './IReplaceable';

export class CustomizationBloomingCamp implements IReplaceable {
  private replacements: { [key: Text]: Text } = {
    Wish: 'Want'
  };

  replaceWithMapping(input: Text): Text {
    for (const [target, replacement] of Object.entries(this.replacements)) {
      input = input.replace(new RegExp(target, 'g'), replacement);
    }
    return input;
  }
}
