/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchQuestionnaireForAdminSortCol = typeof SearchQuestionnaireForAdminSortCol[keyof typeof SearchQuestionnaireForAdminSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchQuestionnaireForAdminSortCol = {
  bs_questionnairesupdatedAt: 'bs_questionnaires.updatedAt',
  bs_questionnairesreleasedAt: 'bs_questionnaires.releasedAt',
  bs_questionnairesanswerDueAt: 'bs_questionnaires.answerDueAt',
} as const;
