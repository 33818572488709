import { ScreenV2 } from '@atomica.co/components';
import { Message, Name, Title } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import React, { CSSProperties } from 'react';
import ErrorMessage from '../errors/ErrorMessage';
import atomica from './../../assets/atomica.gif';

type LoadingType = 'circular' | 'image';

interface P {
  style?: CSSProperties;
  loading?: boolean;
  loadingType?: LoadingType;
  errorMsg?: Message;
  title?: Title;
  className: Name;
  children?: React.ReactNode;
}

const Screen: React.FC<P> = React.memo(props => {
  const { style, loading = false, loadingType = 'image', errorMsg = EMPTY, title, className, children } = props;

  return (
    <>
      {!errorMsg && (
        <ScreenV2
          style={style}
          loading={loading}
          src={loadingType === 'image' ? atomica : undefined}
          className={className}
          title={title}
        >
          {children}
        </ScreenV2>
      )}

      {!!errorMsg && <ErrorMessage message={errorMsg} />}
    </>
  );
});

Screen.displayName = 'Screen';
export default Screen;
