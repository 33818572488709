import { DOMAINS, HTTP, HTTPS, toLiffUrl } from '@atomica.co/irori';
import { Id, URL } from '@atomica.co/types';
import { EMPTY, EQUAL, EnvDef, QUESTION_MARK } from '@atomica.co/utils';
import env from '../env/env';

export const convertToBrowserUrlFromLiffUrl = (redirectURL: URL, lineLiffId: Id | undefined): URL => {
  const liffPath = `${toLiffUrl(env, lineLiffId)}${QUESTION_MARK}redirectPath${EQUAL}`;
  const pathToOpen = redirectURL.replace(liffPath, EMPTY);
  const browserUrl = `${env === EnvDef.LOCAL ? HTTP : HTTPS}://${DOMAINS[env]}${pathToOpen}`;
  return decodeURIComponent(browserUrl);
};
