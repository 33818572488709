import { CachedSpaceReservationActionEnum } from '../../enums/action-enum';
import { CachedSpaceReservationAction } from '../../models/action-model';
import { CachedSpaceReservation } from '../hooks/useCachedSpaceReservation';

export const toSpaceReservationAction = (
  cachedSpaceReservation: CachedSpaceReservation
): CachedSpaceReservationAction => {
  return {
    type: CachedSpaceReservationActionEnum.SET,
    cachedSpaceReservation
  };
};
