/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractDiscountV2DtoRecurrencePattern = typeof ContractDiscountV2DtoRecurrencePattern[keyof typeof ContractDiscountV2DtoRecurrencePattern];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractDiscountV2DtoRecurrencePattern = {
  at_once: 'at_once',
  monthly: 'monthly',
} as const;
