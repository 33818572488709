import { URL } from '@atomica.co/types';
import { CachedURLActionEnum } from '../../enums/action-enum';
import { CachedURLAction } from '../../models/action-model';

export const toCachedURLAction = (cachedURL: URL): CachedURLAction => {
  return {
    type: CachedURLActionEnum.SET,
    cachedURL
  };
};
