/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type WishDtoStatus = typeof WishDtoStatus[keyof typeof WishDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WishDtoStatus = {
  todo: 'todo',
  'in-progress': 'in-progress',
  waiting: 'waiting',
  done: 'done',
  discarded: 'discarded',
} as const;
