/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type UpdateResourceReservationPaymentStatusForAdminBodyPaymentStatus = typeof UpdateResourceReservationPaymentStatusForAdminBodyPaymentStatus[keyof typeof UpdateResourceReservationPaymentStatusForAdminBodyPaymentStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateResourceReservationPaymentStatusForAdminBodyPaymentStatus = {
  payment_in_progress: 'payment_in_progress',
  additional_payment_in_progress: 'additional_payment_in_progress',
  paid: 'paid',
  unpaid: 'unpaid',
} as const;
