/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type UserDtoAuthority = typeof UserDtoAuthority[keyof typeof UserDtoAuthority];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDtoAuthority = {
  admin: 'admin',
  member: 'member',
} as const;
