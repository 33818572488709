export enum SearchOption {
  OFFSET = 0,
  LIMIT = 500
}

export enum ReservationModalMode {
  NEW = 'new',
  EDIT = 'edit',
  READONLY = 'readonly'
}

export enum SpaceReservationActionEnum {
  CONFIRM = 'confirm',
  REJECT = 'reject'
}
