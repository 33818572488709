import { Code } from '@atomica.co/types';
import { CachedInitialAuthorityCodeActionEnum } from '../../enums/action-enum';
import { CachedInitialAuthorityCodeAction } from '../../models/action-model';
import { EMPTY } from '@atomica.co/utils';

const cachedInitialAuthorityCodeReducer = (
  cachedInitialAuthorityCode: Code,
  action: CachedInitialAuthorityCodeAction
): Code | undefined => {
  if (cachedInitialAuthorityCode === undefined) return EMPTY;

  switch (action.type) {
    case CachedInitialAuthorityCodeActionEnum.SET:
      return action.cachedInitialAuthorityCode;

    case CachedInitialAuthorityCodeActionEnum.PURGE:
      return EMPTY;

    default:
      return cachedInitialAuthorityCode;
  }
};

export default cachedInitialAuthorityCodeReducer;
