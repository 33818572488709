/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type EventV2DtoParticipatingMembershipType = typeof EventV2DtoParticipatingMembershipType[keyof typeof EventV2DtoParticipatingMembershipType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventV2DtoParticipatingMembershipType = {
  all: 'all',
  limited: 'limited',
} as const;
