import { Message } from '@atomica.co/types';
import { SafeParseReturnType } from 'zod';
import { aggregateValidation } from '../../common';
import { accountWishSchema, RegisterAccountWishForm } from './schema';

export const validateRegisterAccountWish = (userWish: RegisterAccountWishForm): Message => {
  return aggregateValidation([validate(userWish)]);
};

const validate = (
  userWish: RegisterAccountWishForm
): SafeParseReturnType<RegisterAccountWishForm, RegisterAccountWishForm> => {
  return accountWishSchema.safeParse(userWish);
};
