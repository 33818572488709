export enum MenuDefCodeEnum {
  USERS = 'users',
  USERS_V2 = 'users_v2',
  RESERVATIONS = 'reservations',
  TODAYS_USERS = 'todays_users',
  QUESTIONNAIRE = 'questionnaire',
  CONTRACTS = 'contracts',
  CONTRACTS_V2 = 'contracts_v2',
  BILLING = 'billing',
  WISHES = 'wishes',
  EVENTS = 'events',
  DATA_EXPORT = 'data_export',
  MASTER = 'master',
  SHOT_MASTER = 'shot_master',
  RESOURCE_MASTER = 'resource_master',
  CONSUMER_HOME = 'consumer_home',
  CONSUMER_RESERVE = 'consumer_reserve',
  CONSUMER_EVENTS = 'consumer_events',
  CONSUMER_SHOT = 'consumer_shot',
  CONSUMER_MY_PAGE = 'consumer_my_page',
  CONSUMER_MEMBERS = 'consumer_members'
}
