/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchSpaceReservationsForAdminStatus = typeof SearchSpaceReservationsForAdminStatus[keyof typeof SearchSpaceReservationsForAdminStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchSpaceReservationsForAdminStatus = {
  all: 'all',
  canceled: 'canceled',
  provisional: 'provisional',
  confirmed: 'confirmed',
  provisional_or_confirmed: 'provisional_or_confirmed',
  rejected: 'rejected',
} as const;
