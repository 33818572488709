import { Contract, ContractQuantityInputType, HOUR } from '@atomica.co/irori';
import { DetailForContractRegisterScreen } from '../converters/contract-v2-converter';

export const isTimeBasedPlan = (contract: Contract | undefined): boolean => {
  if (!contract) return false;
  return contract.contractPlan.includes(HOUR);
};

export const isNumberOfUsers = (detail: DetailForContractRegisterScreen): boolean => {
  return detail.quantityInputType === ContractQuantityInputType.NUMBER_OF_USERS;
};
