import { Component, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseDto } from '@atomica.co/irori';
import { Email } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import { SignUpInSendEmailStatus } from '../../../../enums/account-enum';
import { SignInStatus } from '../SignIn';
import EmailSent from './../../email-sent/EmailSent';
import InputEmail from './parts/InputEmail';

export enum ResetPasswordStatus {
  INPUT_EMAIL,
  EMAIL_SENT
}

interface P {
  base: BaseDto;
  index: ResetPasswordStatus;
  setResetPasswordIndex: (status: ResetPasswordStatus) => void;
  setSignInIndex: (status: SignInStatus) => void;
  goBack(): void;
}

const ResetPassword: React.FC<P> = React.memo(props => {
  const { base, index, setResetPasswordIndex, setSignInIndex, goBack } = props;
  const unmountRef = useUnmountRef();
  const [email, setEmail] = useSafeState<Email>(unmountRef, EMPTY);

  const showEmailSentMessage = useSafeCallback(
    (email: Email): void => {
      setEmail(email);
      setResetPasswordIndex(ResetPasswordStatus.EMAIL_SENT);
    },
    [setEmail, setResetPasswordIndex]
  );

  const handleBackButtonClicked = useSafeCallback((): void => {
    setResetPasswordIndex(ResetPasswordStatus.INPUT_EMAIL);
    setSignInIndex(SignInStatus.SELECT_SIGN_IN_OPTION);
  }, [setResetPasswordIndex, setSignInIndex]);

  const children = useMemo<React.ReactElement>(() => {
    switch (index) {
      case ResetPasswordStatus.INPUT_EMAIL:
        return <InputEmail base={base} onSendEmail={showEmailSentMessage} goBack={goBack} />;

      case ResetPasswordStatus.EMAIL_SENT:
        return (
          <EmailSent email={email} status={SignUpInSendEmailStatus.RESET_PASSWORD} onClick={handleBackButtonClicked} />
        );
    }
  }, [base, email, goBack, handleBackButtonClicked, index, showEmailSentMessage]);

  return <Component className='reset-password'>{children}</Component>;
});

ResetPassword.displayName = 'ResetPassword';
export default ResetPassword;
