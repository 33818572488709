/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type CameraRollActionType = typeof CameraRollActionType[keyof typeof CameraRollActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CameraRollActionType = {
  cameraRoll: 'cameraRoll',
} as const;
