import { Email } from '@atomica.co/types';
import { CachedEmailActionEnum } from '../../enums/action-enum';
import { CachedEmailAction } from '../../models/action-model';

export const toCachedEmailAction = (cachedEmail: Email): CachedEmailAction => {
  return {
    type: CachedEmailActionEnum.SET,
    cachedEmail
  };
};
