import { MyPageTabEnum, RegisterAccountStepEnum, SignUpInTabEnum } from '../enums/account-enum';
import { Labels } from '../models/common-model';

export const REGISTER_ACCOUNT_STEP_LABELS: Labels = {
  [RegisterAccountStepEnum.SUMMARY]: '概要',
  [RegisterAccountStepEnum.INPUT]: '入力',
  [RegisterAccountStepEnum.AUTHORIZATION]: '認証',
  [RegisterAccountStepEnum.COMPLETION]: '完了'
};

export const SIGN_UP_IN_TAB_LABELS: Labels = {
  [SignUpInTabEnum.SIGN_UP]: '新規登録',
  [SignUpInTabEnum.SIGN_IN]: 'ログイン'
};

export const MYPAGE_TAB_LABELS: Labels = {
  [MyPageTabEnum.OVERVIEW]: 'ご登録情報',
  [MyPageTabEnum.USAGE_INFO]: 'ご利用状況',
  [MyPageTabEnum.ENTER_LEAVE_HISTORY]: 'エントランス入退履歴'
};
