/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type EventScheduleV2DtoEntryFormSendAtUnit = typeof EventScheduleV2DtoEntryFormSendAtUnit[keyof typeof EventScheduleV2DtoEntryFormSendAtUnit];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventScheduleV2DtoEntryFormSendAtUnit = {
  daysAgo: 'daysAgo',
  weeksAgo: 'weeksAgo',
  monthsAgo: 'monthsAgo',
} as const;
