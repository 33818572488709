import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BaseId, FETCH_PROFILE, FetchProfile, FetchProfileRequest, FetchProfileResponse } from '@atomica.co/irori';
import { UserId } from '@atomica.co/types';
import { builder } from '@atomica.co/utils';
import useCommonRequest from '../../redux/hooks/useCommonRequest';

interface P {
  profile: FetchProfile | undefined;
  loadProfile: (userId: UserId, baseId: BaseId) => Promise<boolean>;
}

function useProfile(): P {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [profile, setProfile] = useSafeState<FetchProfile | undefined>(unmountRef, undefined);

  const loadProfile = useSafeCallback(
    async (userId: UserId, baseId: BaseId): Promise<boolean> => {
      const request = builder<FetchProfileRequest>().baseId(baseId).userId(userId).build();
      const response = await commonRequest<FetchProfileRequest, FetchProfileResponse>(FETCH_PROFILE, request);
      if (!response.result) {
        setProfile(undefined);
        return response.result;
      }
      setProfile(response);
      return response.result;
    },
    [commonRequest, setProfile]
  );

  return {
    profile,
    loadProfile
  };
}

export default useProfile;
