import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { BASE_CODE, BaseDto, FETCH_BASE, FetchBaseRequest, FetchBaseResponse } from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';
import { useEffect } from 'react';
import useCommonRequest from '../../redux/hooks/useCommonRequest';
import usePath from './usePath';

interface P {
  loaded: boolean;
  base: BaseDto | undefined;
}

function useBase(): P {
  const { params } = usePath();
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [firstLoaded, setFirstLoaded] = useSafeState<boolean>(unmountRef, false);
  const [base, setBase] = useSafeState<BaseDto | undefined>(unmountRef);

  const _getBase = useSafeCallback(async (): Promise<BaseDto | undefined> => {
    const baseCode = params[BASE_CODE];
    if (!baseCode) return;

    // const { data: response } = await getBsBase().fetchBase(baseCode);
    const request = builder<FetchBaseRequest>().baseCode(baseCode).build();
    const response = await commonRequest<FetchBaseRequest, FetchBaseResponse>(FETCH_BASE, request);
    return response.base;
    // return response.base as BaseDto;
  }, [params]);

  const loadBase = useSafeCallback(async (): Promise<void> => {
    const base = await _getBase();
    setBase(base);
    setFirstLoaded(true);
  }, [_getBase, setBase, setFirstLoaded]);

  useEffect(() => {
    if (base) return;
    loadBase();
  }, [base, loadBase]);

  return {
    loaded: firstLoaded,
    base
  } as P;
}

export default useBase;
