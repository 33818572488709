/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type EventScheduleV2DtoRecurrenceType = typeof EventScheduleV2DtoRecurrenceType[keyof typeof EventScheduleV2DtoRecurrenceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventScheduleV2DtoRecurrenceType = {
  everyWeek: 'everyWeek',
  everyMonth: 'everyMonth',
  everyYear: 'everyYear',
  everyWeekday: 'everyWeekday',
  notRecurring: 'notRecurring',
} as const;
