import { LabelV2, themeV2 } from '@atomica.co/components';
import { EMPTY } from '@atomica.co/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { EntryExitHook } from '../../../../../redux/hooks/useRoomEntryExit';

interface P {
  entryExitHook: EntryExitHook;
}

const DeleteContent: React.FC<P> = React.memo(props => {
  const {
    entryExitHook: { data }
  } = props;

  const { entryDate, entryTime, exitDate, exitTime } = data;

  const formatDate = useMemo(
    () => (date: string, time: string) => {
      if (date === EMPTY || time === EMPTY) {
        return '--/--/-- --:--';
      }
      return `${date.replace(/-/g, '/')} ${time}`;
    },
    []
  );

  const dateTimeRange = useMemo(() => {
    const entryDateTimeFormatted = formatDate(entryDate, entryTime);
    const exitDateTimeFormatted = formatDate(exitDate, exitTime);
    return `${entryDateTimeFormatted} 〜 ${exitDateTimeFormatted}`;
  }, [formatDate, entryDate, entryTime, exitDate, exitTime]);

  return (
    <Content>
      以下の入退室履歴を削除してよろしいですか？
      <FormWrapper>
        <LabelV2 fontSize={'medium'} text={'入室・退室日時'} />
        <div data-testid='date-time-range'>{dateTimeRange}</div>
      </FormWrapper>
    </Content>
  );
});

DeleteContent.displayName = 'DeleteContent';
export default DeleteContent;

const Content = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
`;
