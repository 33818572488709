/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchResourceForAdmin200CancelPoliciesItemCancelFeeType = typeof FetchResourceForAdmin200CancelPoliciesItemCancelFeeType[keyof typeof FetchResourceForAdmin200CancelPoliciesItemCancelFeeType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchResourceForAdmin200CancelPoliciesItemCancelFeeType = {
  percent: 'percent',
  amount: 'amount',
} as const;
