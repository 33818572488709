import { InvitationType } from '@atomica.co/irori';
import { Label, Message, Name } from '@atomica.co/types';

export const InvitationTypeLabel: Record<InvitationType, Label> = {
  [InvitationType.CONTRACT]: '会員'
};

export const validInvitationMessage = (baseName: Name, invitationTypeLabel: Label): Message => {
  return `${baseName}より\n${invitationTypeLabel}の招待が届いております`;
};

export const differntEmailMessage = (): Message => {
  return '別のアカウントを利用するか、招待メールアドレスに関してスタッフにお問い合わせください。';
};

export const joinedMessage = (baseName: Name): Message => {
  return `ようこそ${baseName}へ！`;
};

export const joinedSubMessage = (invitationTypeLabel: Label): Message => {
  return `${invitationTypeLabel}登録が無事完了しました。これから${invitationTypeLabel}として素晴らしい体験があなたを待っています！`;
};
