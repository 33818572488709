import { URL } from '@atomica.co/types';
import { CachedJoinURLActionEnum } from '../../enums/action-enum';
import { CachedJoinURLAction } from '../../models/action-model';

export const toCachedJoinURLAction = (cachedJoinURL: URL): CachedJoinURLAction => {
  return {
    type: CachedJoinURLActionEnum.SET,
    cachedJoinURL
  };
};
