import { Code } from '@atomica.co/types';
import { CachedInitialAuthorityCodeActionEnum } from '../../enums/action-enum';
import { CachedInitialAuthorityCodeAction } from '../../models/action-model';

export const toCachedInitialAuthorityCodeAction = (
  cachedInitialAuthorityCode: Code | undefined
): CachedInitialAuthorityCodeAction => {
  return {
    type: CachedInitialAuthorityCodeActionEnum.SET,
    cachedInitialAuthorityCode
  };
};
