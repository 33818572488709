/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexTextAllOfPosition = typeof FlexTextAllOfPosition[keyof typeof FlexTextAllOfPosition];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexTextAllOfPosition = {
  relative: 'relative',
  absolute: 'absolute',
} as const;
