import { CachedUserListActionEnum } from '../../enums/action-enum';
import { CachedUserListAction } from '../../models/action-model';
import { CachedUserList, initialCachedUserList } from '../hooks/useCachedUserList';

const cachedUserListReducer = (
  billingList: CachedUserList,
  action: CachedUserListAction
): CachedUserList | undefined => {
  if (billingList === undefined) return initialCachedUserList;

  switch (action.type) {
    case CachedUserListActionEnum.SET:
      return action.cachedUserList;

    case CachedUserListActionEnum.PURGE:
      return initialCachedUserList;

    default:
      return billingList;
  }
};

export default cachedUserListReducer;
