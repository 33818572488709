import { Token } from '@atomica.co/types';
import firebase, { auth } from '../firebase';

export class AuthService {
  public static async signInWithCustomToken(customToken: Token): Promise<firebase.auth.UserCredential> {
    return await new Promise((resolve, reject) => {
      auth
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(async () => {
          const res = await auth.signInWithCustomToken(customToken);
          resolve(res);
        })
        .catch(error => reject(error));
    });
  }

  public static async signInWithRedirect({
    provider,
    scopes
  }: {
    provider?: firebase.auth.GoogleAuthProvider | firebase.auth.OAuthProvider;
    scopes?: string[];
  } = {}): Promise<void> {
    return await new Promise((resolve, reject) => {
      auth
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(async () => {
          const _provider = provider || new firebase.auth.GoogleAuthProvider();
          scopes?.forEach(scope => {
            _provider.addScope(scope);
          });
          const res = await auth.signInWithRedirect(_provider);
          resolve(res);
        })
        .catch(error => reject(error));
    });
  }
}
