import { Status } from '@atomica.co/components';
import { WishStatus } from '@atomica.co/irori';

export const toStatus = (status: WishStatus): Status => {
  switch (status) {
    case WishStatus.TODO:
      return 'default';
    case WishStatus.DONE:
      return 'success';
    case WishStatus.IN_PROGRESS:
      return 'pink';
    case WishStatus.WAITING:
      return 'warning';
    case WishStatus.DISCARDED:
      return 'error';
  }
};
