import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { User, WithBaseId } from '@atomica.co/irori';
import { Count } from '@atomica.co/types';
import { SearchUsersForWishForAdminParams } from '../../__generated/model';
import { getBsUser } from '../../__generated/admin/bs-user/bs-user';

interface P {
  searchedUsers: User[];
  totalCount: Count;
  clear: () => void;
  search: (request: WithBaseId<SearchUsersForWishForAdminParams>) => Promise<void>;
}

function useSearchedUsers(): P {
  const unmountRef = useUnmountRef();
  const [searchedUsers, setSearchedUsers] = useSafeState<User[]>(unmountRef, []);
  const [totalCount, setTotalCount] = useSafeState<Count>(unmountRef, 0);

  const search = useSafeCallback(
    async (request: WithBaseId<SearchUsersForWishForAdminParams>): Promise<void> => {
      const { baseId, ...params } = request;
      const { data } = await getBsUser().searchUsersForWishForAdmin(baseId, params);
      setSearchedUsers(data.users as User[]);
      setTotalCount(data.totalCount);
    },
    [setSearchedUsers, setTotalCount]
  );

  const clear = useSafeCallback((): void => {
    setSearchedUsers([]);
  }, [setSearchedUsers]);

  return {
    searchedUsers,
    totalCount,
    search,
    clear
  };
}

export default useSearchedUsers;
