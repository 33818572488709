import { QuestionnaireItemType, QuestionnaireStatusForSearching } from '@atomica.co/irori';
import { QuestionnaireAnswerStepEnum, QuestionnaireTabEnum } from '../enums/questionnaire-enum';
import { Labels } from '../models/common-model';

export const QUESTIONNAIRE_ITEM_TYPE_LABEL: Labels = {
  [QuestionnaireItemType.CHECKBOX]: 'チェックボックス（複数選択）',
  [QuestionnaireItemType.RADIO]: 'ラジオボタン（単一選択）',
  [QuestionnaireItemType.TEXT]: '記述式'
};

export const QUESTIONNAIRE_STATUS_LABEL: Labels = {
  [QuestionnaireStatusForSearching.ALL]: 'すべてのステータス',
  [QuestionnaireStatusForSearching.DRAFT]: '下書き',
  [QuestionnaireStatusForSearching.PRIVATE]: '非公開',
  [QuestionnaireStatusForSearching.RELEASED]: '公開中',
  [QuestionnaireStatusForSearching.EXPIRED]: '公開終了'
};

export const QUESTIONNAIRE_TAB_LABEL: Labels = {
  [QuestionnaireTabEnum.QUESTIONNAIRE]: 'アンケート',
  [QuestionnaireTabEnum.ANSWER]: '回答'
};

export const QUESTIONNAIRE_ANSWER_STEP_LABEL: Labels = {
  [QuestionnaireAnswerStepEnum.ANSWER]: '回答',
  [QuestionnaireAnswerStepEnum.CONFIRMATION]: '確認'
};
