import { CachedBillingListActionEnum } from '../../enums/action-enum';
import { CachedBillingListAction } from '../../models/action-model';
import { CachedBillingInfo } from '../hooks/useCachedBillingList';

export const toBillingListAction = (cachedBillingList: CachedBillingInfo): CachedBillingListAction => {
  return {
    type: CachedBillingListActionEnum.SET,
    cachedBillingList
  };
};
