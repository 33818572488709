import { CachedUserListActionEnum } from '../../enums/action-enum';
import { CachedUserListAction } from '../../models/action-model';
import { CachedUserList } from '../hooks/useCachedUserList';

export const toUserListAction = (cachedUserList: CachedUserList): CachedUserListAction => {
  return {
    type: CachedUserListActionEnum.SET,
    cachedUserList
  };
};
