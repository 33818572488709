import { ButtonV2, Component, InputWithLabelV2, themeV2 } from '@atomica.co/components';
import { Email, Message } from '@atomica.co/types';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { INPUT_EMAIL_BUBBLE } from '../../../texts/step-varification-text';
import MojakoBubble from '../mojako-babble/MojakoBubble';

interface P extends RouteComponentProps {
  email: Email;
  errorMessage?: Message;
  onBlur: () => void;
  onChange: (value: Email) => void;
  sendVerification: () => void;
}

const InputEmail: React.FC<P> = React.memo(props => {
  const { email, errorMessage, onBlur, onChange, sendVerification } = props;

  return (
    <Component style={{ width: '100%' }} className='invalid-invitation'>
      <Context>
        <MojakoBubble text={INPUT_EMAIL_BUBBLE} />
        <Wrapper data-testid='step-varification-input-email'>
          <InputWithLabelV2
            text={'メールアドレス'}
            value={email}
            required
            errorMessage={errorMessage}
            onChange={onChange}
            placeholder='example@example.jp'
            onBlur={onBlur}
          />
        </Wrapper>
        <ButtonV2 size='medium' type='primary' label='認証コード送信' onClick={sendVerification} />
      </Context>
    </Component>
  );
});

InputEmail.displayName = 'InputEmail';
export default InputEmail;

const Context = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 100%;
`;
