import { BaseDto, User, Wish, WishId, WishScope, WishStatus, WishType } from '@atomica.co/irori';
import { Text } from '@atomica.co/types';
import { builder } from '@atomica.co/utils';

export const toWish = (
  wishId: WishId,
  type: WishType,
  status: WishStatus,
  linkSlack: boolean,
  scope: WishScope,
  content: Text,
  remarks: Text,
  base: BaseDto,
  fromUser: User,
  toUser: User
): Wish => {
  return builder<Wish>()
    .wishId(wishId)
    .type(type)
    .status(status)
    .linkSlack(linkSlack)
    .scope(scope)
    .content(content)
    .remarks(remarks)
    .base(base)
    .fromUser(fromUser)
    .toUser(toUser)
    .build();
};
