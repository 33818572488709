import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseId,
  FETCH_UNITS_V2_FOR_ADMIN,
  FetchUnitsV2ForAdminRequest,
  FetchUnitsV2ForAdminResponse,
  UnitV2
} from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';
import useCommonRequest from '../../redux/hooks/useCommonRequest';

interface P {
  units: UnitV2[];
  fetchUnits: (baseId: BaseId) => Promise<void>;
}

function useUnit(): P {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [units, setUnits] = useSafeState<UnitV2[]>(unmountRef, []);

  const fetchUnits = useSafeCallback(
    async (baseId: BaseId): Promise<void> => {
      const request = builder<FetchUnitsV2ForAdminRequest>().baseId(baseId).build();
      const response = await commonRequest<FetchUnitsV2ForAdminRequest, FetchUnitsV2ForAdminResponse>(
        FETCH_UNITS_V2_FOR_ADMIN,
        request
      );
      setUnits(response.units);
    },
    [commonRequest, setUnits]
  );

  return {
    units,
    fetchUnits
  };
}

export default useUnit;
