import { ContractV2, InvitationStatus, User } from '@atomica.co/irori';
import { hasLength } from '@atomica.co/utils';

export const isValidContractUser = (contract: ContractV2, targetUser?: User): boolean => {
  if (!hasLength(contract.contractUsers)) return false;

  return contract.contractUsers
    .filter(contractUser => contractUser?.user?.userId === targetUser?.userId)
    .some(contractUser => contractUser.invitationStatus === InvitationStatus.REGISTERED);
};
