import { RECORD_LOGS, RecordLogsRequest, RecordLogsResponse, toFuncName } from '@atomica.co/irori';
import { Json } from '@atomica.co/types';
import { RequestType, builder, convert } from '@atomica.co/utils';
import { functions } from '../firebase';

/** @deprecated */
export default class LogRequest {
  public static saveInfoLog = async (jsonToRecord: Json): Promise<RecordLogsResponse> => {
    const request = builder<RecordLogsRequest>().jsonToRecord(jsonToRecord).build();

    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(RECORD_LOGS));

      func(convert(RequestType.SENDER, request))
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
