/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceDtoDefaultReservationStatus = typeof SpaceDtoDefaultReservationStatus[keyof typeof SpaceDtoDefaultReservationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceDtoDefaultReservationStatus = {
  canceled: 'canceled',
  provisional: 'provisional',
  confirmed: 'confirmed',
  rejected: 'rejected',
  payment_in_progress: 'payment_in_progress',
  additional_payment_in_progress: 'additional_payment_in_progress',
  hold_for_change: 'hold_for_change',
  blocked_google_calendar: 'blocked_google_calendar',
  removed_google_calendar: 'removed_google_calendar',
} as const;
