import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseId,
  FETCH_USER_WISH,
  FetchUserWishRequest,
  FetchUserWishResponse,
  UserWishData,
  UserWishStatus,
  UserWishStatusWithoutDiscarded
} from '@atomica.co/irori';
import { UserId } from '@atomica.co/types';
import { builder } from '@atomica.co/utils';
import useCommonRequest from './useCommonRequest';

interface P {
  userWishes: UserWishData[];
  loadUserWish: (
    userId: UserId,
    baseId: BaseId,
    statuses?: UserWishStatusWithoutDiscarded[]
  ) => Promise<UserWishData[]>;
}

function useUserWish(): P {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [userWishes, setUserWishes] = useSafeState<UserWishData[]>(unmountRef, []);

  const loadUserWish = useSafeCallback(
    async (
      userId: UserId,
      baseId: BaseId,
      statuses: UserWishStatusWithoutDiscarded[] = [UserWishStatus.IN_PROGRESS]
    ): Promise<UserWishData[]> => {
      const request = builder<FetchUserWishRequest>().userId(userId).baseId(baseId).statuses(statuses).build();
      const response = await commonRequest<FetchUserWishRequest, FetchUserWishResponse>(FETCH_USER_WISH, request);
      if (!response.result) {
        setUserWishes([]);
        return [];
      }
      setUserWishes(response.userWishes);

      return response.userWishes;
    },
    [commonRequest, setUserWishes]
  );

  return {
    userWishes,
    loadUserWish
  };
}

export default useUserWish;
