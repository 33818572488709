import { themeV2 } from '@atomica.co/components';
import { Title } from '@atomica.co/types';
import React from 'react';
import styled from 'styled-components';

interface P {
  title: Title;
  children?: React.ReactNode;
}

const Card: React.FC<P> = React.memo(props => {
  const { title, children } = props;

  return (
    <Container>
      <Header>{title}</Header>
      {children}
    </Container>
  );
});

Card.displayName = 'Card';
export default Card;

const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 8px;
`;

const Header = styled.div`
  ${themeV2.mixins.v2.typography.title.xLarge};
  color: ${themeV2.mixins.v2.color.font.black};
  padding-bottom: ${themeV2.mixins.v2.spacing}px;
  border-bottom: 1px solid ${themeV2.mixins.v2.color.border.gray};
`;
