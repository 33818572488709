import { Status } from '@atomica.co/components';
import { UserWishStatus, UserWishStatusWithoutDiscarded } from '@atomica.co/irori';

export const toStatus = (status: UserWishStatusWithoutDiscarded): Status => {
  switch (status) {
    case UserWishStatus.IN_PROGRESS:
      return 'success';
    case UserWishStatus.DONE:
      return 'default';
  }
};
