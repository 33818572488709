import { CachedContractListActionEnum } from '../../enums/action-enum';
import { CachedContractListAction } from '../../models/action-model';
import { CachedSearchContractInfo, INITIAL_INFO } from '../hooks/useCachedContractList';

const cachedContractListReducer = (
  contractList: CachedSearchContractInfo,
  action: CachedContractListAction
): CachedSearchContractInfo | undefined => {
  if (contractList === undefined) return INITIAL_INFO;

  switch (action.type) {
    case CachedContractListActionEnum.SET:
      return action.cachedContractList;

    case CachedContractListActionEnum.PURGE:
      return INITIAL_INFO;

    default:
      return contractList;
  }
};

export default cachedContractListReducer;
