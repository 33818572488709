/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchResourceForAdmin200ReservationType = typeof FetchResourceForAdmin200ReservationType[keyof typeof FetchResourceForAdmin200ReservationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchResourceForAdmin200ReservationType = {
  time: 'time',
  date: 'date',
} as const;
