import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  FETCH_USER_INFLOW_SOURCES,
  FetchUserInflowSourcesRequest,
  FetchUserInflowSourcesResponse,
  UserInflowSource
} from '@atomica.co/irori';
import { Code } from '@atomica.co/types';
import { builder } from '@atomica.co/utils';
import useCommonRequest from './useCommonRequest';

interface P {
  userInflowSources: UserInflowSource[];
  loadUserInflowSources: (baseCode: Code) => Promise<void>;
}

function useUserInflowSource(): P {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [userInflowSources, setUserInflowSources] = useSafeState<UserInflowSource[]>(unmountRef, []);

  const loadUserInflowSources = useSafeCallback(
    async (baseCode: Code): Promise<void> => {
      const request = builder<FetchUserInflowSourcesRequest>().baseCode(baseCode).build();
      const response = await commonRequest<FetchUserInflowSourcesRequest, FetchUserInflowSourcesResponse>(
        FETCH_USER_INFLOW_SOURCES,
        request
      );
      setUserInflowSources(response.userInflowSources);
    },
    [commonRequest, setUserInflowSources]
  );

  return {
    userInflowSources,
    loadUserInflowSources
  };
}

export default useUserInflowSource;
