/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BaseMenuDtoMenuType = typeof BaseMenuDtoMenuType[keyof typeof BaseMenuDtoMenuType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseMenuDtoMenuType = {
  console: 'console',
  consumer: 'consumer',
} as const;
