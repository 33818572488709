import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { URL } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { toCachedJoinURLAction } from '../actions/cached-join-url-action';
import store from '../store';

interface P {
  clearCachedJoinURL: () => void;
  saveCachedJoinURL: (url: URL) => void;
  hasCachedJoinURL: () => boolean;
  openCachedJoinURL: () => void;
}

function useCachedJoinURL(): P {
  const unmountRef = useUnmountRef();
  const [cachedJoinURL, setCachedJoinURL] = useSafeState<URL>(unmountRef, store.getState().cachedJoinURL);

  const saveCachedJoinURL = useSafeCallback(
    (url: URL): void => {
      setCachedJoinURL(url);
      const action = toCachedJoinURLAction(url);
      store.dispatch(action);
    },
    [setCachedJoinURL]
  );

  const clearCachedJoinURL = useSafeCallback((): void => {
    setCachedJoinURL(EMPTY);
    const action = toCachedJoinURLAction(EMPTY);
    store.dispatch(action);
  }, [setCachedJoinURL]);

  const hasCachedJoinURL = useSafeCallback((): boolean => {
    return !!cachedJoinURL;
  }, [cachedJoinURL]);

  const openCachedJoinURL = useSafeCallback((): void => {
    clearCachedJoinURL();
    window.location.href = cachedJoinURL;
  }, [cachedJoinURL, clearCachedJoinURL]);

  return {
    clearCachedJoinURL,
    saveCachedJoinURL,
    hasCachedJoinURL,
    openCachedJoinURL
  } as P;
}

export default useCachedJoinURL;
