import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Email } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { toCachedEmailAction } from '../actions/cached-email-action';
import store from '../store';

interface P {
  cachedEmail: Email;
  saveCachedEmail: (email: Email) => void;
  clearCachedEmail: () => void;
}

function useCachedEmail(): P {
  const unmountRef = useUnmountRef();
  const [cachedEmail, setCachedEmail] = useSafeState<Email>(unmountRef, store.getState().cachedEmail);

  const saveCachedEmail = useSafeCallback(
    (email: Email): void => {
      setCachedEmail(email);
      const action = toCachedEmailAction(email);
      store.dispatch(action);
    },
    [setCachedEmail]
  );

  const clearCachedEmail = useSafeCallback((): void => {
    setCachedEmail(EMPTY);
    const action = toCachedEmailAction(EMPTY);
    store.dispatch(action);
  }, [setCachedEmail]);

  return {
    cachedEmail,
    saveCachedEmail,
    clearCachedEmail
  } as P;
}

export default useCachedEmail;
