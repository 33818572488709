/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceDtoCategory = typeof SpaceDtoCategory[keyof typeof SpaceDtoCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceDtoCategory = {
  entrance: 'entrance',
  conference: 'conference',
  device: 'device',
} as const;
