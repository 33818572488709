/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchContractUsersV2ForAdminSortCol = typeof SearchContractUsersV2ForAdminSortCol[keyof typeof SearchContractUsersV2ForAdminSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchContractUsersV2ForAdminSortCol = {
  bs_contract_users_v2invitationEmail: 'bs_contract_users_v2.invitationEmail',
  bs_contract_users_v2invitationStatus: 'bs_contract_users_v2.invitationStatus',
  bs_usersfamilyName: 'bs_users.familyName',
  bs_usersphone: 'bs_users.phone',
  bs_contract_users_v2updatedAt: 'bs_contract_users_v2.updatedAt',
} as const;
