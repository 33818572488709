/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceReservationDtoPaymentMethod = typeof SpaceReservationDtoPaymentMethod[keyof typeof SpaceReservationDtoPaymentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceReservationDtoPaymentMethod = {
  cash: 'cash',
  credit_card: 'credit_card',
} as const;
