/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type UserEntityDirection = typeof UserEntityDirection[keyof typeof UserEntityDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEntityDirection = {
  enter: 'enter',
  exit: 'exit',
} as const;
