import { combineReducers } from 'redux';
import cachedBillingListReducer from './cached-billing-list-reducer';
import cachedContractListReducer from './cached-contract-list-reducer';
import cachedDataForSaveSpaceReservationAutomaticallyReducer from './cached-data-for-save-space-reservation-automatically-reducer';
import cachedEmailReducer from './cached-email-reducer';
import cachedInitialAuthorityCodeReducer from './cached-initial-authority-code-reducer';
import cachedInvitationEmailReducer from './cached-invitation-email-reducer';
import cachedJoinURLReducer from './cached-join-url-reducer';
import cachedSpaceReservationReducer from './cached-space-reservation-reducer';
import cachedURLReducer from './cached-url-reducer';
import cachedUserListReducer from './cached-user-list-reducer';
import previousURLReducer from './previous-url-reducer';
import processReducer from './process-reducer';
import questionnaireReducer from './questionnaire-reducer';

export default combineReducers({
  process: processReducer,
  previousURL: previousURLReducer,
  cachedURL: cachedURLReducer,
  cachedSpaceReservation: cachedSpaceReservationReducer,
  cachedDataForSaveSpaceReservationAutomatically: cachedDataForSaveSpaceReservationAutomaticallyReducer,
  cachedContractList: cachedContractListReducer,
  cachedBillingList: cachedBillingListReducer,
  cachedUserList: cachedUserListReducer,
  cachedEmail: cachedEmailReducer,
  cachedInvitationEmail: cachedInvitationEmailReducer,
  cachedInitialAuthorityCode: cachedInitialAuthorityCodeReducer,
  cachedQuestionnaireInfo: questionnaireReducer,
  cachedJoinURL: cachedJoinURLReducer
});
