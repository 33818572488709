export enum SearchOption {
  OFFSET = 0,
  LIMIT = 20
}

export enum UserAction {
  OPERATE_ALL = 'operate_all',
  DETAIL_ALL = 'detail_all',
  UPDATE_USER_AUTHORITY = 'update_user_authority',
  UPDATE_RANK = 'update_rank',
  UPDATE_RESOURCE_AUTHORITY = 'update_resource_authority'
  // DELETE_ALL = 'delete_all'
}

/** NOTE: ユーザー詳細画面 */
export const USER_DETAIL_FEATURES = {
  OVERVIEW: 'OVERVIEW',
  POINT_USAGE_LOG: 'POINT_USAGE_LOG',
  ROOM_ENTRY_EXIT_HISTORY: 'ROOM_ENTRY_EXIT_HISTORY'
} as const;
export type UserDetailFeature = keyof typeof USER_DETAIL_FEATURES;

/** NOTE: ユーザー詳細画面 - 入退室履歴機能 */
export const USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES = {
  REGISTER: 'REGISTER',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
} as const;
export type UserDetailRoomEntryExitHistoryFeatures = keyof typeof USER_DETAIL_ROOM_ENTRY_EXIT_HISTORY_FEATURES;

/** @deprecated */
export enum UserInflowSource {
  INTERNET = 'internet',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TELEVISION = 'television',
  RADIO = 'radio',
  REFERRAL = 'referral',
  MAGAZIN = 'magazin',
  NEWSPAPER = 'newspaper',
  OTHER = 'other'
}
