import { EventScheduleGroupId, EventScheduleGroupV2, EventScheduleV2 } from '@atomica.co/irori';
import { Name, Option } from '@atomica.co/types';
import { builder, hasLength } from '@atomica.co/utils';
import { Labels } from '../models/common-model';

export const toEventScheduleGroupV2 = (
  eventScheduleGroupId: EventScheduleGroupId,
  name: Name,
  eventSchedule: EventScheduleV2
): EventScheduleGroupV2 => {
  return builder<EventScheduleGroupV2>()
    .eventScheduleGroupId(eventScheduleGroupId)
    .name(name)
    .eventSchedule(eventSchedule)
    .build();
};

export const toGroupOptions = (groups: EventScheduleGroupV2[]): Option[] => {
  if (!hasLength(groups)) return [];
  return groups.map(group => group.eventScheduleGroupId);
};

export const toGroupLabels = (groups: EventScheduleGroupV2[]): Labels => {
  if (!hasLength(groups)) return {};
  const labels: Labels = {};
  for (const group of groups) {
    labels[group.eventScheduleGroupId] = group.name;
  }
  return labels;
};
