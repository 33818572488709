/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchUsersForAdminSortCol = typeof SearchUsersForAdminSortCol[keyof typeof SearchUsersForAdminSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchUsersForAdminSortCol = {
  dateOfAgreement: 'dateOfAgreement',
  email: 'email',
  familyName: 'familyName',
  firstName: 'firstName',
  phone: 'phone',
  authorityCode: 'authorityCode',
} as const;
