import { Index } from '@atomica.co/types';
import { getRandomInt, NOT_FOUND_INDEX, ONE, ZERO } from '@atomica.co/utils';

type Rotate = 'none' | 'right' | 'left';

export const getRotate = (): Rotate => {
  const num = getRandomInt(2);

  switch (num) {
    case ZERO:
      return 'right';
    case ONE:
      return 'left';
    default:
      return 'none';
  }
};

export const partialBuilder = <T>(data: T, partialData: Partial<T>): T => {
  return Object.assign({}, data, partialData);
};

export const isNotFoundIndex = (idx: Index | null): boolean => idx === NOT_FOUND_INDEX;
