import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { FETCH_USER_DIVS, FetchUserDivsRequest, FetchUserDivsResponse, UserDiv } from '@atomica.co/irori';
import { Code } from '@atomica.co/types';
import { builder } from '@atomica.co/utils';
import useCommonRequest from './useCommonRequest';

interface P {
  userDivs: UserDiv[];
  loadUserDivs: (baseCode: Code) => Promise<void>;
}

function useUserDiv(): P {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [userDivs, setUserDivs] = useSafeState<UserDiv[]>(unmountRef, []);

  const loadUserDivs = useSafeCallback(
    async (baseCode: Code): Promise<void> => {
      const request = builder<FetchUserDivsRequest>().baseCode(baseCode).build();
      const response = await commonRequest<FetchUserDivsRequest, FetchUserDivsResponse>(FETCH_USER_DIVS, request);
      setUserDivs(response.userDivs);
    },
    [commonRequest, setUserDivs]
  );

  return {
    userDivs,
    loadUserDivs
  };
}

export default useUserDiv;
