import { PriceUtils, TaxDiv, TaxId } from '@atomica.co/irori';
import { Key, Label, Price, Quantity, Rate } from '@atomica.co/types';
import { AND, EMPTY, ZERO, toTaxIncluded } from '@atomica.co/utils';
import { TAX_DIV_LABELS } from '../texts/tax-text';

type SplitedReverseKey = [taxDiv: TaxDiv, taxId: TaxId, taxRate: Rate];

export const getTaxKey = (taxId: TaxId | undefined, taxDiv: TaxDiv | undefined, taxRate: Rate | undefined): Label => {
  if (!taxId || !taxDiv || taxRate == null) return EMPTY;
  return `${taxDiv}${AND}${taxId}${AND}${taxRate}`;
};

export const reverseTaxKey = (taxKey: Key): { taxDiv?: TaxDiv; taxId?: TaxId; taxRate?: Rate } => {
  if (!taxKey) return {};

  const [taxDiv, taxId, taxRate] = taxKey.split(AND) as SplitedReverseKey;
  return { taxId, taxDiv, taxRate };
};

export const getTaxLabel = (taxDiv: TaxDiv | undefined, taxRate: Rate | undefined): Label => {
  if (!taxDiv || taxRate == null) return EMPTY;
  return taxDiv === TaxDiv.EXEMPT ? TAX_DIV_LABELS[taxDiv] : `${TAX_DIV_LABELS[taxDiv]} ${taxRate}%`;
};

export const calcTaxIncludedAmountPrice = (
  unitPrice: Price,
  quantity: Quantity,
  taxDiv: TaxDiv | undefined,
  taxRate: Rate | undefined
): Price => {
  if (!unitPrice || !quantity || !taxDiv || taxRate == null) return ZERO;
  const amount = PriceUtils.applyAmountPrice(unitPrice, quantity);
  return taxDiv === TaxDiv.EXCLUDED ? toTaxIncluded(amount, taxRate) : amount;
};
