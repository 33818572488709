/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractV2DtoInflowSource = typeof ContractV2DtoInflowSource[keyof typeof ContractV2DtoInflowSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractV2DtoInflowSource = {
  direct: 'direct',
  organic: 'organic',
  shot: 'shot',
} as const;
