import { convert, EnvDef, RequestType } from '@atomica.co/utils';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuth } from 'firebase/auth';
import env from '../env/env';

export const AXIOS_INSTANCE = Axios.create();

const getBaseUrl = (): string | undefined => {
  switch (env) {
    case EnvDef.LOCAL:
    case EnvDef.TEST:
      return 'http://127.0.0.1:3001';
    case EnvDef.DEVELOPMENT:
      return 'https://dev.api.atomica.me';
    case EnvDef.STAGING:
      return 'https://staging.api.atomica.me';
    case EnvDef.PRODUCTION:
      return 'https://api.atomica.me';
  }
};

export const privateAxiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  const source = Axios.CancelToken.source();

  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    const token = await user.getIdToken();
    const promise = AXIOS_INSTANCE({
      baseURL: getBaseUrl(),
      ...config,
      ...options,
      cancelToken: source.token,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => ({ ...response, data: convert(RequestType.RECEIVER, response.data) }));

    return promise;
  }

  throw new Error('user is not found');
};

export const publicAxiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  const source = Axios.CancelToken.source();

  const promise = AXIOS_INSTANCE({
    baseURL: getBaseUrl(),
    ...config,
    ...options,
    cancelToken: source.token
  }).then(data => data);

  return promise;
};

export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
