import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Sort, User, UserSortColumn } from '@atomica.co/irori';
import { Count, Offset, UserId, Word } from '@atomica.co/types';
import { EMPTY, ZERO } from '@atomica.co/utils';
import { toUserListAction } from '../actions/user-list-action';
import store from '../store';

export interface CachedUserList {
  users: User[];
  selectedUserIds: UserId[];
  offset: Offset;
  sortKey: UserSortColumn;
  sort: Sort;
  searchingWord: Word;
  totalRecordCount?: Count;
}

interface P {
  cachedUserList: CachedUserList;
  saveCachedUserList: (userList: CachedUserList) => void;
  clearCachedUserList: () => void;
}

export const initialCachedUserList: CachedUserList = {
  users: [],
  offset: ZERO,
  searchingWord: EMPTY,
  selectedUserIds: [],
  sort: Sort.DESC,
  sortKey: UserSortColumn.EMAIL,
  totalRecordCount: ZERO
};

function useCachedUserList(): P {
  const unmountRef = useUnmountRef();
  const [cachedUserList, setCachedUserList] = useSafeState<CachedUserList>(unmountRef, store.getState().cachedUserList);

  const saveCachedUserList = useSafeCallback(
    (billingList: CachedUserList): void => {
      setCachedUserList(billingList);
      const action = toUserListAction(billingList);
      store.dispatch(action);
    },
    [setCachedUserList]
  );

  const clearCachedUserList = useSafeCallback((): void => {
    setCachedUserList(initialCachedUserList);
    const action = toUserListAction(initialCachedUserList);
    store.dispatch(action);
  }, [setCachedUserList]);

  return {
    cachedUserList,
    saveCachedUserList,
    clearCachedUserList
  } as P;
}

export default useCachedUserList;
