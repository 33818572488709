/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceDtoSpaceManagement = typeof SpaceDtoSpaceManagement[keyof typeof SpaceDtoSpaceManagement];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceDtoSpaceManagement = {
  bitkey: 'bitkey',
  denso: 'denso',
} as const;
