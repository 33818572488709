export enum ValidateTypeEnum {
  EMPTY = 'empty',
  NON_ZERO_NUMBER = 'nonZeroNumber',
  POSITIVE_NUMBER = 'positiveNumber',
  POSITIVE_NUMBER_OR_ZERO = 'positiveNumberOrZero',
  NEGATIVE_NUMBER = 'negativeNumber',
  POST = 'post',
  EMAIL = 'email',
  KATAKANA = 'katakana',
  UNDEFINED = 'undefined',
  ALPHABET_NUMBER_SYMBOL = 'alphabetNumberSymbol',
  PHONE_NUMBER = 'phoneNumber'
}
