export const DAYS_OF_WEEK_IDX = [0, 1, 2, 3, 4, 5, 6];

export const DAYS_OF_WEEK_LABELS = ['日', '月', '火', '水', '木', '金', '土'];

export const DEFAULT_DATE_TIME = '00:00:00';

export const END_OF_DAY = '24:00:00';

export const ZERO_MINUTES = '00';

export const THIRTY_MINUTES = '30';

export const DEFAULT_START_UNIT = 30;

export const SPACE_RESERVATION_OPERATION = 'spaceReservationOperation';

export const MAXIMAMU_RESERVE_LIMIT_AUTOMATICALLY = 10;
