/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type CancelPolicyDtoCancelFeeSpanType = typeof CancelPolicyDtoCancelFeeSpanType[keyof typeof CancelPolicyDtoCancelFeeSpanType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancelPolicyDtoCancelFeeSpanType = {
  day: 'day',
  hour: 'hour',
} as const;
