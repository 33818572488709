import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BaseId,
  FETCH_TAXES_V2_FOR_ADMIN,
  FetchTaxesV2ForAdminRequest,
  FetchTaxesV2ForAdminResponse,
  TaxV2
} from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';
import useCommonRequest from '../../redux/hooks/useCommonRequest';

interface P {
  taxes: TaxV2[];
  fetchTaxes: (baseId: BaseId) => Promise<void>;
}

function useTax(): P {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [taxes, setTaxes] = useSafeState<TaxV2[]>(unmountRef, []);

  const fetchTaxes = useSafeCallback(
    async (baseId: BaseId): Promise<void> => {
      const request = builder<FetchTaxesV2ForAdminRequest>().baseId(baseId).build();
      const response = await commonRequest<FetchTaxesV2ForAdminRequest, FetchTaxesV2ForAdminResponse>(
        FETCH_TAXES_V2_FOR_ADMIN,
        request
      );
      setTaxes(response.taxes);
    },
    [commonRequest, setTaxes]
  );

  return {
    taxes,
    fetchTaxes
  };
}

export default useTax;
