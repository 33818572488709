import { ButtonV2, DialogV2, LabelV2, themeV2 } from '@atomica.co/components';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Message, Title } from '@atomica.co/types';

interface P {
  isOpen: boolean;
  title: Title;
  message: Message;
  onClose(): void;
}

const ReservationErrorModal: React.FC<P> = React.memo(props => {
  const { isOpen, message, onClose, title } = props;

  const footerButtons = useMemo<React.ReactNode[]>(() => {
    return [<ButtonV2 key='cancel' size='medium' label='閉じる' onClick={onClose} />];
  }, [onClose]);

  return (
    <DialogV2 isOpen={isOpen} headerLabel={title} buttonsOnTheRight={footerButtons} onClose={onClose} width={360}>
      <FormWrapper>
        <LabelV2 text={message} fontSize='large' />
      </FormWrapper>
    </DialogV2>
  );
});

ReservationErrorModal.displayName = 'ReservationErrorModal';
export default ReservationErrorModal;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
`;
