export enum Process {
  INITIAL = 'initial',
  PROCESSING = 'processing',
  COMPLETED = 'completed'
}

export enum ProcessActionEnum {
  SET = 'set_process',
  PURGE = 'persist/PURGE'
}

export enum PreviousURLActionEnum {
  SET = 'set_previous_url',
  PURGE = 'persist/PURGE'
}
export enum CachedURLActionEnum {
  SET = 'set_cached_url',
  PURGE = 'persist/PURGE'
}

export enum CachedInitialAuthorityCodeActionEnum {
  SET = 'set_cached_initial_authority_code',
  PURGE = 'persist/PURGE'
}

export enum CachedSpaceReservationActionEnum {
  SET = 'set_cached_space_reservation',
  PURGE = 'persist/PURGE'
}

export enum CachedDataForSaveSpaceReservationAutomaticallyActionEnum {
  SET = 'set_cached_data_for_save_space_reservation_automatically',
  PURGE = 'persist/PURGE'
}

export enum CachedContractListActionEnum {
  SET = 'set_cached_contract_list',
  PURGE = 'persist/PURGE'
}

export enum CachedBillingListActionEnum {
  SET = 'set_cached_billing_list',
  PURGE = 'persist/PURGE'
}

export enum CachedUserListActionEnum {
  SET = 'set_cached_user_list',
  PURGE = 'persist/PURGE'
}

export enum CachedEmailActionEnum {
  SET = 'set_cached_email',
  PURGE = 'persist/PURGE'
}

export enum CachedInvitationEmailActionEnum {
  SET = 'set_cached_invitation_email',
  PURGE = 'persist/PURGE'
}

export enum CachedQuestionnaireActionEnum {
  SET = 'set_cached_questionnaire',
  PURGE = 'persist/PURGE'
}

export enum CachedJoinURLActionEnum {
  SET = 'set_cached_join_url',
  PURGE = 'persist/PURGE'
}
