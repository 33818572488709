import z from 'zod';

export const resourceMasterSchema = z.object({
  resourceCode: z.string().min(1, 'リソースコードを入力してください'),
  resourceName: z.string().min(1, 'リソース名称を入力してください'),
  unitPrice: z.number()
  // photo: z.custom<File>().refine(file => !!file, { message: 'リソース写真を選択してください' })
});

export type ResourceForm = z.infer<typeof resourceMasterSchema>;
