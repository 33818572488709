/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchResourceForAdmin200TaxTaxDiv = typeof FetchResourceForAdmin200TaxTaxDiv[keyof typeof FetchResourceForAdmin200TaxTaxDiv];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchResourceForAdmin200TaxTaxDiv = {
  exempt: 'exempt',
  included: 'included',
  excluded: 'excluded',
} as const;
