import { CheckBoxV2, customMedia, themeV2, themeV3 } from '@atomica.co/components';
import { Id } from '@atomica.co/types';
import React from 'react';
import styled from 'styled-components';

interface P {
  id: Id;
  selectedIds: Id[];
  handleClicked: (spacesId: Id) => void;
  children: React.ReactNode;
}

const SpaceCard: React.FC<P> = React.memo(props => {
  const { id, selectedIds, handleClicked, children } = props;

  return (
    <Container>
      <CustomCard onClick={() => handleClicked(id)} isBorderShown={selectedIds.includes(id)}>
        <CardContent>
          <CheckBoxV2 shrink checked={selectedIds.includes(id)} onChange={() => handleClicked(id)} />
          {children}
        </CardContent>
      </CustomCard>
    </Container>
  );
});

SpaceCard.displayName = 'SpaceCard';
export default SpaceCard;

const Container = styled.div`
  max-width: 330px;
  width: 100%;
  position: relative;
  color: ${themeV2.mixins.v2.color.font.white};

  ${customMedia.lessThan('small')`
    max-width: 100%;
    width: 100%;
  `};
`;

const CustomCard = styled.div<{ isBorderShown: boolean }>`
  position: relative;
  visibility: visible;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  ${({ isBorderShown }) =>
    `border: 2px solid ${isBorderShown ? themeV3.mixins.v3.color.border.primary : 'transparent'};`}
  border-radius: 16px;
`;

const CardContent = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${themeV2.mixins.v2.spacing * 2}px;
  gap: ${themeV2.mixins.v2.spacing * 1.5}px;
  min-height: 160px;
`;
