/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchBillingsV2ForAdminPaymentMethod = typeof SearchBillingsV2ForAdminPaymentMethod[keyof typeof SearchBillingsV2ForAdminPaymentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchBillingsV2ForAdminPaymentMethod = {
  credit_card: 'credit_card',
  bank_transfer: 'bank_transfer',
  invoice: 'invoice',
  cash: 'cash',
} as const;
