import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { FETCH_CITIES, FetchCitiesRequest, FetchCitiesResponse, PrefectureWithCities } from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';
import useCommonRequest from './useCommonRequest';

interface P {
  prefectureWithCities: PrefectureWithCities[];
  loadPrefectureWithCities: () => Promise<void>;
}

function useCity(): P {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [prefectureWithCities, setPrefectureWithCities] = useSafeState<PrefectureWithCities[]>(unmountRef, []);

  const loadPrefectureWithCities = useSafeCallback(async (): Promise<void> => {
    const request = builder<FetchCitiesRequest>().build();
    const response = await commonRequest<FetchCitiesRequest, FetchCitiesResponse>(FETCH_CITIES, request);
    setPrefectureWithCities(response.prefectures);
  }, [commonRequest, setPrefectureWithCities]);

  return {
    prefectureWithCities,
    loadPrefectureWithCities
  };
}

export default useCity;
