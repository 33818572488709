import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { URL } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { getBaseFromURL } from '../../utils/path-util';
import { toCachedURLAction } from '../actions/cached-url-action';
import store from '../store';

const IGNORED_DIRECTORIES = ['signup', 'signin', 'signout', 'account/new'];

const isIgnored = (url: URL): boolean => {
  return url ? !!IGNORED_DIRECTORIES.find(dir => url.includes(dir)) : false;
};

interface P {
  cachedURL: URL;
  clearCachedURL: () => void;
  saveCachedURL: (url: URL) => void;
  saveCurrentURL: () => void;
  hasCachedURL: () => boolean;
  openCachedURL: () => void;
}

function useCachedURL(): P {
  const unmountRef = useUnmountRef();
  const [cachedURL, setCachedURL] = useSafeState<URL>(unmountRef, store.getState().cachedURL);

  const saveCachedURL = useSafeCallback(
    (url: URL): void => {
      if (isIgnored(url)) return;
      setCachedURL(url);
      const action = toCachedURLAction(url);
      store.dispatch(action);
    },
    [setCachedURL]
  );

  const clearCachedURL = useSafeCallback((): void => {
    setCachedURL(EMPTY);
    const action = toCachedURLAction(EMPTY);
    store.dispatch(action);
  }, [setCachedURL]);

  const saveCurrentURL = useSafeCallback((): void => {
    const currentURL = window.location.href;
    saveCachedURL(currentURL);
  }, [saveCachedURL]);

  const hasCachedURL = useSafeCallback((): boolean => {
    const currentBase = getBaseFromURL(window.location.href);
    const cachedBase = getBaseFromURL(cachedURL);
    return currentBase !== cachedBase || isIgnored(cachedURL) ? false : !!cachedURL;
  }, [cachedURL]);

  const openCachedURL = useSafeCallback((): void => {
    clearCachedURL();
    window.location.href = cachedURL;
  }, [cachedURL, clearCachedURL]);

  return {
    cachedURL,
    clearCachedURL,
    saveCachedURL,
    saveCurrentURL,
    hasCachedURL,
    openCachedURL
  } as P;
}

export default useCachedURL;
