/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchResourcesForAdmin200ResourcesItemPublish = typeof SearchResourcesForAdmin200ResourcesItemPublish[keyof typeof SearchResourcesForAdmin200ResourcesItemPublish];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchResourcesForAdmin200ResourcesItemPublish = {
  全体: '全体',
  契約者限定: '契約者限定',
} as const;
