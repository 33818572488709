/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractV2DtoInvoiceDeliveryMethod = typeof ContractV2DtoInvoiceDeliveryMethod[keyof typeof ContractV2DtoInvoiceDeliveryMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractV2DtoInvoiceDeliveryMethod = {
  post: 'post',
  email: 'email',
  none: 'none',
} as const;
