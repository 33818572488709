import { BaseAuthority } from '@atomica.co/irori';
import { Index, Option } from '@atomica.co/types';
import { Labels } from '../models/common-model';

export const convertToOption = (authorities: BaseAuthority[]): Option[] => {
  return authorities.map((auth, idx) => idx.toString());
};

export const convertToLabel = (authorities: BaseAuthority[]): Labels => {
  return authorities.reduce((prev: Labels, current: BaseAuthority, idx: Index) => {
    prev[idx] = current.authorityName;
    return prev;
  }, {});
};
