/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceReservationDtoReservationType = typeof SpaceReservationDtoReservationType[keyof typeof SpaceReservationDtoReservationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceReservationDtoReservationType = {
  time: 'time',
  date: 'date',
} as const;
