export enum ExportTarget {
  SHOT_USAGE = 'shot_usage',
  CONTRACT = 'contract',
  CONTRACT_USAGE = 'contract_usage',
  ACCESS = 'access',
  // MEETAT_PARTICIPANT = 'meetat_participant'
  SPACE_RESERVATION = 'space_reservation',
  USER = 'user',
  SPACE_RESERVATION_PAYMENT = 'space_reservation_payment'
}
