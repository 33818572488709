/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceParticipantDtoParticipantDiv = typeof SpaceParticipantDtoParticipantDiv[keyof typeof SpaceParticipantDtoParticipantDiv];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceParticipantDtoParticipantDiv = {
  owner: 'owner',
  member: 'member',
} as const;
