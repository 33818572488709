/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexTextAllOfDecoration = typeof FlexTextAllOfDecoration[keyof typeof FlexTextAllOfDecoration];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexTextAllOfDecoration = {
  none: 'none',
  underline: 'underline',
  'line-through': 'line-through',
} as const;
