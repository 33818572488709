import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ButtonV2, DialogV2, themeV2, themeV3 } from '@atomica.co/components';
import { Label, Width } from '@atomica.co/types';

interface ButtonProps {
  disabled?: boolean;
  label: Label;
  onClick: () => void;
}

interface P {
  width?: Width;
  isOpen: boolean;
  headerLabel: Label;
  leftButtonProps?: Partial<ButtonProps>;
  rightButtonProps: ButtonProps;
  children?: React.ReactNode;
  onClose: () => void;
}

const DefaultModal: React.FC<P> = React.memo(props => {
  const { width, isOpen, headerLabel, leftButtonProps, rightButtonProps, children, onClose } = props;

  const footerButtons = useMemo<JSX.Element[]>(() => {
    return [
      <ButtonV2
        key='cancel'
        disabled={leftButtonProps?.disabled}
        label={leftButtonProps?.label ?? 'キャンセル'}
        onClick={leftButtonProps?.onClick ?? onClose}
      />,
      <ButtonV2
        key='primary'
        disabled={rightButtonProps.disabled}
        width={105}
        type='primary'
        label={rightButtonProps.label}
        onClick={rightButtonProps.onClick}
      />
    ];
  }, [leftButtonProps, onClose, rightButtonProps]);

  return (
    <DialogV2
      width={width}
      isOpen={isOpen}
      headerLabel={headerLabel}
      buttonsOnTheRight={footerButtons}
      onClose={onClose}
    >
      <Content>{children}</Content>
    </DialogV2>
  );
});

DefaultModal.displayName = 'DefaultModal';
export default DefaultModal;

const Content = styled.div`
  padding: ${themeV2.mixins.v2.spacing * 2}px ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV3.mixins.v3.color.container.neutral.white};
`;
