/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexButtonAllOfAdjustMode = typeof FlexButtonAllOfAdjustMode[keyof typeof FlexButtonAllOfAdjustMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexButtonAllOfAdjustMode = {
  'shrink-to-fit': 'shrink-to-fit',
} as const;
