import { SendingMethod, SendingStatus } from '@atomica.co/irori';
import { DateUnitEnum } from '@atomica.co/utils';
import { AssignMethod } from '../enums/event-v2-enum';
import { Labels } from '../models/common-model';

export const MESSAGE_SENDING_METHOD: Labels = {
  [SendingMethod.AUTO]: '日時を指定して自動送信',
  [SendingMethod.MANUAL]: '今すぐ送信',
  [SendingMethod.NOT_SEND]: '送信しない'
};

export const HOW_TO_ASSIGN_PARTICIPANTS: Labels = {
  [AssignMethod.REVERT]: 'グループ未割り当てに戻す',
  [AssignMethod.CHANGE]: '指定のグループに割り当て'
};

export const COMMON_MESSAGE_SENDING_STATUS_LABELS: Labels = {
  [SendingStatus.SUSPENDED]: '一時保存',
  [SendingStatus.RETRY]: 'エラー',
  [SendingStatus.ERROR]: 'エラー'
};

export const ENTRY_FORM_MESSAGE_SENDING_STATUS_LABELS: Labels = {
  [SendingStatus.NOT_SENT]: '未送信',
  [SendingStatus.WAITING]: '送信予定',
  [SendingStatus.COMPLETED]: '送信済み',
  ...COMMON_MESSAGE_SENDING_STATUS_LABELS
};

export const REMIND_MESSAGE_SENDING_STATUS_LABELS: Labels = {
  [SendingStatus.NOT_SENT]: '未送信',
  [SendingStatus.WAITING]: '送信予定',
  [SendingStatus.COMPLETED]: '送信済み',
  ...COMMON_MESSAGE_SENDING_STATUS_LABELS
};

export const MESSAGE_DATE_UNIT_LABELS: Labels = {
  [DateUnitEnum.DAYS_AGO]: '日',
  [DateUnitEnum.WEEKS_AGO]: '週間',
  [DateUnitEnum.MONTHS_AGO]: 'ヶ月'
};
