/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type EventScheduleV2DtoEntryFormSendMethod = typeof EventScheduleV2DtoEntryFormSendMethod[keyof typeof EventScheduleV2DtoEntryFormSendMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventScheduleV2DtoEntryFormSendMethod = {
  auto: 'auto',
  manual: 'manual',
  not_send: 'not_send',
} as const;
