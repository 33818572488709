/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchMessagesV2ForAdminMessageType = typeof SearchMessagesV2ForAdminMessageType[keyof typeof SearchMessagesV2ForAdminMessageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchMessagesV2ForAdminMessageType = {
  entry: 'entry',
  remind: 'remind',
  date_changed: 'date_changed',
  not_checkout: 'not_checkout',
} as const;
