import { DateAndTimeField, themeV2 } from '@atomica.co/components';
import React from 'react';
import { EntryExitHook, EntryExitErrors } from '../../../../../redux/hooks/useRoomEntryExit';
import styled from 'styled-components';

interface P {
  entryExitHook: EntryExitHook;
  errors?: EntryExitErrors;
}

const UpsertContent: React.FC<P> = React.memo(props => {
  const {
    entryExitHook: { data, changeHandlers },
    errors
  } = props;

  return (
    <Content>
      <DateAndTimeField
        label={'入室日時'}
        required
        dateValue={data.entryDate}
        timeValue={data.entryTime}
        setDateValue={changeHandlers.onEntryDateChange}
        setTimeValue={changeHandlers.onEntryTimeChange}
        errorMessage={errors?.entryDate?.[0] || errors?.entryTime?.[0] || ''}
      />
      <DateAndTimeField
        label={'退室日時'}
        required
        dateValue={data.exitDate}
        timeValue={data.exitTime}
        setDateValue={changeHandlers.onExitDateChange}
        setTimeValue={changeHandlers.onExitTimeChange}
        errorMessage={errors?.exitDate?.[0] || errors?.exitTime?.[0] || ''}
      />
    </Content>
  );
});

UpsertContent.displayName = 'UpsertContent';
export default UpsertContent;

const Content = styled.div`
  ${themeV2.mixins.v2.typography.body.medium};
  display: flex;
  flex-direction: column;
  padding: ${themeV2.mixins.v2.spacing * 3}px ${themeV2.mixins.v2.spacing * 3}px;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  background: ${themeV2.mixins.v2.color.background.offWhite};
`;
