export const BOTTOM_NAVIGATOR_HEIGHT = 72;

export const SUB_BUTTON_HEIGHT = 64;

export const DISPLAY_AVATOR_COUNT = 3;

export const TERMS_OF_USE_FOR_KNOT_PLACE = 'https://like-steed-cec.notion.site/a9bc4492f66d4204937f8a8ecdba7435';

export const PRIVACY_POLICY_FOR_KNOT_PLACE = 'https://like-steed-cec.notion.site/40050ba0d066402aaac60e84c8b5e9e5';

export const TERMS_OF_USE_FOR_WORKHUB = 'https://terms.bitkey.co.jp/top/workhubpass_consumer';

export const PRIVACY_POLICY_FOR_WORKHUB = 'https://terms.bitkey.co.jp/top/privacy-policy';

export const PRODUCTION_WORKHUB_PASS_URL = 'https://pass.workhub.site/login';

export const STAGING_WORKHUB_PASS_URL = 'https://pass.staging-workhub.site/login';

export const ACCOUNT_DELETION_URL = 'https://forms.gle/YgC4eLmY1YEHxChF7';
