import { SendingStatus } from '@atomica.co/irori';
import { Statuses } from '../models/common-model';

export const MESSAGE_SENDING_STATUS: Statuses | string = {
  [SendingStatus.SUSPENDED]: 'warning',
  [SendingStatus.WAITING]: 'default',
  [SendingStatus.COMPLETED]: 'success',
  [SendingStatus.RETRY]: 'error',
  [SendingStatus.ERROR]: 'error',
  [SendingStatus.NOT_SENT]: 'error'
};
