/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchResourceForAdmin200CancelPoliciesItemCancelFeeSpanType = typeof FetchResourceForAdmin200CancelPoliciesItemCancelFeeSpanType[keyof typeof FetchResourceForAdmin200CancelPoliciesItemCancelFeeSpanType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchResourceForAdmin200CancelPoliciesItemCancelFeeSpanType = {
  day: 'day',
  hour: 'hour',
} as const;
