import { CachedQuestionnaireActionEnum } from '../../enums/action-enum';
import { CachedQuestionnaireAction } from '../../models/action-model';
import { CachedQuestionnaireInfo, getInitialCachedQuestionnaireInfo } from '../hooks/useQuestionnaire';

const cachedQuestionnaireReducer = (
  cachedQuestionnaireInfo: CachedQuestionnaireInfo,
  action: CachedQuestionnaireAction
): CachedQuestionnaireInfo | undefined => {
  if (cachedQuestionnaireInfo === undefined) return getInitialCachedQuestionnaireInfo();

  switch (action.type) {
    case CachedQuestionnaireActionEnum.SET:
      return action.cachedQuestionnaireInfo;

    case CachedQuestionnaireActionEnum.PURGE:
      return getInitialCachedQuestionnaireInfo();

    default:
      return cachedQuestionnaireInfo;
  }
};

export default cachedQuestionnaireReducer;
