import { Message } from '@atomica.co/types';
import { SafeParseReturnType } from 'zod';
import { aggregateValidation } from '../../common';
import { accountProfileEditSchema, EditAccountProfileForm } from './schema';

export const validateEditProfile = (profile: EditAccountProfileForm): Message => {
  return aggregateValidation([validate(profile)]);
};

const validate = (
  profile: EditAccountProfileForm
): SafeParseReturnType<EditAccountProfileForm, EditAccountProfileForm> => {
  return accountProfileEditSchema.safeParse(profile);
};
