import { CachedSpaceReservationActionEnum } from '../../enums/action-enum';
import { CachedSpaceReservationAction } from '../../models/action-model';
import { CachedSpaceReservation, initialCachedSpaceReservation } from '../hooks/useCachedSpaceReservation';

const cachedSpaceReservationReducer = (
  spaceReservation: CachedSpaceReservation,
  action: CachedSpaceReservationAction
): CachedSpaceReservation | undefined  => {
  if (spaceReservation === undefined) return initialCachedSpaceReservation;

  switch (action.type) {
    case CachedSpaceReservationActionEnum.SET:
      return action.cachedSpaceReservation;

    case CachedSpaceReservationActionEnum.PURGE:
      return initialCachedSpaceReservation;

    default:
      return spaceReservation;
  }
};

export default cachedSpaceReservationReducer;
