import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Space } from '@atomica.co/irori';
import { toSpaceReservationAction } from '../actions/space-reserve-action';
import store from '../store';
import { Path } from '../../router/Routes';

export interface CachedSpaceReservation {
  cachedSpace?: Space;
  cachedSelectedDate?: Date;
  cachedSelectedStartDate?: Date;
  cachedSelectedEndDate?: Date;
  fromPath?: Path;
}

interface P {
  cachedSpaceReservation: CachedSpaceReservation;
  saveCachedSpaceReservation: (spaceReservation: CachedSpaceReservation) => void;
  clearCachedSpaceReservation: () => void;
}

export const initialCachedSpaceReservation: CachedSpaceReservation = {
  cachedSpace: undefined,
  cachedSelectedDate: undefined,
  cachedSelectedStartDate: undefined,
  cachedSelectedEndDate: undefined,
  fromPath: undefined
};

function useCachedSpaceReservation(): P {
  const unmountRef = useUnmountRef();
  const [cachedSpaceReservation, setCachedSpaceReservation] = useSafeState<CachedSpaceReservation>(
    unmountRef,
    store.getState().cachedSpaceReservation
  );

  const saveCachedSpaceReservation = useSafeCallback(
    (spaceReservation: CachedSpaceReservation): void => {
      setCachedSpaceReservation(spaceReservation);
      const action = toSpaceReservationAction(spaceReservation);
      store.dispatch(action);
    },
    [setCachedSpaceReservation]
  );

  const clearCachedSpaceReservation = useSafeCallback((): void => {
    setCachedSpaceReservation(initialCachedSpaceReservation);
    const action = toSpaceReservationAction(initialCachedSpaceReservation);
    store.dispatch(action);
  }, [setCachedSpaceReservation]);

  return {
    cachedSpaceReservation,
    saveCachedSpaceReservation,
    clearCachedSpaceReservation
  } as P;
}

export default useCachedSpaceReservation;
