import { Message } from '@atomica.co/types';
import { SafeParseReturnType } from 'zod';
import { aggregateValidation } from '../common';
import { ResourceForm, resourceMasterSchema } from './schema';

export const validate = (resource: ResourceForm): Message => {
  return aggregateValidation([validateResource(resource)]);
};

const validateResource = (resource: ResourceForm): SafeParseReturnType<ResourceForm, ResourceForm> => {
  return resourceMasterSchema.safeParse(resource);
};
