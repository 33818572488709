import { ButtonV2, themeV2 } from '@atomica.co/components';
import { SpaceReservationStatus } from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MOBILE_MAX_WIDTH } from '../../constants/common-const';
import { SPACE_RESERVATION_OPERATION } from '../../constants/space-const';
import usePath from '../../redux/hooks/usePath';
import { Path } from '../../router/Routes';
import RegisteredScreen from '../registered/RegisteredScreen';

export type SpaceReservationOperation = SpaceReservationStatus | 'edit' | 'extension';

const CompleteSpaceReservationScreen: React.FC = React.memo(() => {
  const { params, openBasePath } = usePath();

  const spaceReservationStatus = useMemo<SpaceReservationOperation>(
    () => params[SPACE_RESERVATION_OPERATION],
    [params]
  );

  const labelForRegisteredScreen = useMemo<Label>(() => {
    switch (spaceReservationStatus) {
      case 'extension':
        return '利用時間の延長';
      case 'edit':
        return '予約日時の変更';
      case SpaceReservationStatus.PROVISIONAL:
        return '仮予約';
      case SpaceReservationStatus.CONFIRMED:
      default:
        return '予約';
    }
  }, [spaceReservationStatus]);

  return (
    <RegisteredScreen title={`${labelForRegisteredScreen}が完了しました`}>
      <ButtonWrapper>
        <LargeButton
          type='tertiary'
          label='あなたの予定を確認'
          onClick={() => openBasePath(Path.ACCOUNT_MY_SCHEDULES)}
        />
        <LargeButton label='予約メニューに戻る' onClick={() => openBasePath(Path.RESERVE_SPACE)} />
      </ButtonWrapper>
    </RegisteredScreen>
  );
});

CompleteSpaceReservationScreen.displayName = 'CompleteSpaceReservationScreen';
export default CompleteSpaceReservationScreen;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2}px;
`;

const LargeButton = styled(ButtonV2).attrs(() => ({ size: 'large', isFullWidth: true }))`
  && {
    max-width: ${MOBILE_MAX_WIDTH}px;
  }
`;
