/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type UserDtoPrefecture = typeof UserDtoPrefecture[keyof typeof UserDtoPrefecture];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDtoPrefecture = {
  hokkaido: 'hokkaido',
  aomori: 'aomori',
  iwate: 'iwate',
  miyagi: 'miyagi',
  akita: 'akita',
  yamagata: 'yamagata',
  fukushima: 'fukushima',
  ibaraki: 'ibaraki',
  tochigi: 'tochigi',
  gunma: 'gunma',
  saitama: 'saitama',
  chiba: 'chiba',
  tokyo: 'tokyo',
  kanagawa: 'kanagawa',
  niigata: 'niigata',
  toyama: 'toyama',
  ishikawa: 'ishikawa',
  fukui: 'fukui',
  yamanashi: 'yamanashi',
  nagano: 'nagano',
  gifu: 'gifu',
  shizuoka: 'shizuoka',
  aichi: 'aichi',
  mie: 'mie',
  shiga: 'shiga',
  kyoto: 'kyoto',
  osaka: 'osaka',
  hyogo: 'hyogo',
  nara: 'nara',
  wakayama: 'wakayama',
  tottori: 'tottori',
  shimane: 'shimane',
  okayama: 'okayama',
  hiroshima: 'hiroshima',
  yamaguchi: 'yamaguchi',
  tokushima: 'tokushima',
  kagawa: 'kagawa',
  ehime: 'ehime',
  kochi: 'kochi',
  fukuoka: 'fukuoka',
  saga: 'saga',
  nagasaki: 'nagasaki',
  kumamoto: 'kumamoto',
  oita: 'oita',
  miyazaki: 'miyazaki',
  kagoshima: 'kagoshima',
  okinawa: 'okinawa',
} as const;
