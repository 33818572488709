import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { ContractIdV2, ContractSortColumn, ContractV2, Sort } from '@atomica.co/irori';
import { Count, Offset, Word } from '@atomica.co/types';
import { EMPTY, ZERO } from '@atomica.co/utils';
import { toContractListAction } from '../actions/contract-list-action';
import store from '../store';

export interface CachedSearchContractInfo {
  contracts: ContractV2[];
  selectedContractIds: ContractIdV2[];
  offset: Offset;
  sortKey: ContractSortColumn;
  sort: Sort;
  searchingWord: Word;
  totalRecordCount?: Count;
}

interface P {
  cachedSearchContractInfo: CachedSearchContractInfo;
  saveCachedSearchContractInfo(info: CachedSearchContractInfo): void;
  clearCachedSearchContractInfo(): void;
}

export const INITIAL_INFO: CachedSearchContractInfo = {
  contracts: [],
  offset: ZERO,
  searchingWord: EMPTY,
  selectedContractIds: [],
  sort: Sort.DESC,
  sortKey: ContractSortColumn.CONTRACT_NO
};

function useCachedSearchContractInfo(): P {
  const unmountRef = useUnmountRef();
  const [contractInfo, setContractInfo] = useSafeState<CachedSearchContractInfo>(
    unmountRef,
    store.getState().cachedContractList
  );

  const saveContractInfo = useSafeCallback(
    (contractInfo: CachedSearchContractInfo): void => {
      setContractInfo(contractInfo);
      const action = toContractListAction(contractInfo);
      store.dispatch(action);
    },
    [setContractInfo]
  );

  const clearContractInfo = useSafeCallback((): void => {
    setContractInfo(INITIAL_INFO);
    const action = toContractListAction(INITIAL_INFO);
    store.dispatch(action);
  }, [setContractInfo]);

  return {
    cachedSearchContractInfo: contractInfo,
    saveCachedSearchContractInfo: saveContractInfo,
    clearCachedSearchContractInfo: clearContractInfo
  } as P;
}

export default useCachedSearchContractInfo;
