import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  BillingDateColumn,
  BillingIdV2,
  BillingSortColumn,
  BillingStatus,
  BillingV2,
  PaymentMethod,
  Sort
} from '@atomica.co/irori';
import { Count, Offset, Word } from '@atomica.co/types';
import { EMPTY, ZERO } from '@atomica.co/utils';
import { toBillingListAction } from '../actions/billing-list-action';
import store from '../store';

export interface CachedBillingInfo {
  billings: BillingV2[];
  selectedBillingIds: BillingIdV2[];
  offset: Offset;
  sortKey: BillingSortColumn;
  sort: Sort;
  searchingWord?: Word;
  filterDate?: BillingDateColumn;
  start?: Date;
  end?: Date;
  status?: BillingStatus;
  paymentMethod?: PaymentMethod;
  totalRecordCount?: Count;
}

interface P {
  cachedBillingInfo: CachedBillingInfo;
  saveCachedBillingInfo: (billingList: CachedBillingInfo) => void;
  clearCachedBillingInfo: () => void;
}

export const initialCachedBillingList: CachedBillingInfo = {
  billings: [],
  offset: ZERO,
  searchingWord: EMPTY,
  selectedBillingIds: [],
  sort: Sort.DESC,
  sortKey: BillingSortColumn.BILLING_NO,
  filterDate: BillingDateColumn.CUTOFF_DATE,
  totalRecordCount: ZERO
};

function useCachedBillingList(): P {
  const unmountRef = useUnmountRef();
  const [cachedBillingInfo, setCachedBillingList] = useSafeState<CachedBillingInfo>(
    unmountRef,
    store.getState().cachedBillingList
  );

  const saveCachedBillingInfo = useSafeCallback(
    (billingList: CachedBillingInfo): void => {
      setCachedBillingList(billingList);
      const action = toBillingListAction(billingList);
      store.dispatch(action);
    },
    [setCachedBillingList]
  );

  const clearCachedBillingInfo = useSafeCallback((): void => {
    setCachedBillingList(initialCachedBillingList);
    const action = toBillingListAction(initialCachedBillingList);
    store.dispatch(action);
  }, [setCachedBillingList]);

  return {
    cachedBillingInfo,
    saveCachedBillingInfo,
    clearCachedBillingInfo
  } as P;
}

export default useCachedBillingList;
