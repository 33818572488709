import { CachedDataForSaveSpaceReservationAutomaticallyActionEnum } from '../../enums/action-enum';
import { CachedDataForSaveSpaceReservationAutomaticallyAction } from '../../models/action-model';
import { CachedDataForSaveSpaceReservationAutomatically } from '../hooks/useCachedDataForSaveSpaceReservationAutomatically';

export const toDataForSaveSpaceReservationAutomatically = (
  cachedDataForSaveSpaceReservationAutomatically: CachedDataForSaveSpaceReservationAutomatically
): CachedDataForSaveSpaceReservationAutomaticallyAction => {
  return {
    type: CachedDataForSaveSpaceReservationAutomaticallyActionEnum.SET,
    cachedDataForSaveSpaceReservationAutomatically
  };
};
