import { useSafeState, useUnmountRef } from '@atomica.co/components';
import { User } from '@atomica.co/irori';
import { createContext } from 'react';

export interface ScreenContext {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  user: User | undefined;
  setUserAndPropagate: (user: User | undefined) => void;
}

export const defaultContext: ScreenContext = {
  isDrawerOpen: true,
  setIsDrawerOpen: () => {},
  user: undefined,
  setUserAndPropagate: () => {}
};

export const screenContext = createContext<ScreenContext>(defaultContext);

export const useScreenContext = (): ScreenContext => {
  const unmountRef = useUnmountRef();
  const [isDrawerOpen, setIsDrawerOpen] = useSafeState<boolean>(unmountRef, defaultContext.isDrawerOpen);
  const [user, setUser] = useSafeState<User | undefined>(unmountRef);

  return {
    isDrawerOpen,
    setIsDrawerOpen,
    user,
    setUserAndPropagate: setUser
  };
};
