/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchUsersForAdminSort = typeof SearchUsersForAdminSort[keyof typeof SearchUsersForAdminSort];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchUsersForAdminSort = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;
