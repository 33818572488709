/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type WishSlackContactPostedBy = typeof WishSlackContactPostedBy[keyof typeof WishSlackContactPostedBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WishSlackContactPostedBy = {
  user: 'user',
  admin: 'admin',
} as const;
