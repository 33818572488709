import { BaseDto } from '@atomica.co/irori';
import { Code, Token } from '@atomica.co/types';
import axios from 'axios';
import qs from 'qs';
import { getLineTokenBody } from '../line';
import { LINE_TOKEN_CONFIG, LINE_TOKEN_URL } from '../line/config';

/** @deprecated */
export default class AuthRequest {
  public static fetchIdToken = async (code: Code, base: BaseDto): Promise<Token> => {
    const res = await axios.post(
      LINE_TOKEN_URL,
      qs.stringify(getLineTokenBody(code, base)),
      LINE_TOKEN_CONFIG
    );
    return res.data.id_token;
  };
}
