import { Email } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { CachedInvitationEmailActionEnum } from '../../enums/action-enum';
import { CachedInvitationEmailAction } from '../../models/action-model';

const cachedInvitationEmailReducer = (
  cachedInvitationEmail: Email,
  action: CachedInvitationEmailAction
): Email | undefined => {
  if (cachedInvitationEmail === undefined) return EMPTY;

  switch (action.type) {
    case CachedInvitationEmailActionEnum.SET:
      return action.cachedInvitationEmail;

    case CachedInvitationEmailActionEnum.PURGE:
      return EMPTY;

    default:
      return cachedInvitationEmail;
  }
};

export default cachedInvitationEmailReducer;
