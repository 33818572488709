/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ButtonsTemplateType = typeof ButtonsTemplateType[keyof typeof ButtonsTemplateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ButtonsTemplateType = {
  buttons: 'buttons',
} as const;
