/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * user認証を行うapi
 * OpenAPI spec version: 1.0.0
 */
import type {
  SearchDropInItems200
} from '../../model'
import { privateAxiosInstance } from '../../../axios/custom-instance';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const getBsSpace = () => {
/**
 * ドロップインプランを取得する
 * @summary ドロップインプランを取得する
 */
const searchDropInItems = (
    baseId: string,
 options?: SecondParameter<typeof privateAxiosInstance>,) => {
      return privateAxiosInstance<SearchDropInItems200>(
      {url: `/user/base/${baseId}/drop-in`, method: 'GET'
    },
      options);
    }
  return {searchDropInItems}};
export type SearchDropInItemsResult = NonNullable<Awaited<ReturnType<ReturnType<typeof getBsSpace>['searchDropInItems']>>>
