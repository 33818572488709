import { Email } from '@atomica.co/types';
import { CachedInvitationEmailActionEnum } from '../../enums/action-enum';
import { CachedInvitationEmailAction } from '../../models/action-model';

export const toCachedInvitationEmailAction = (
  cachedInvitationEmail: Email | undefined
): CachedInvitationEmailAction => {
  return {
    type: CachedInvitationEmailActionEnum.SET,
    cachedInvitationEmail
  };
};
