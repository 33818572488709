/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type EventScheduleV2DtoHoldingMethod = typeof EventScheduleV2DtoHoldingMethod[keyof typeof EventScheduleV2DtoHoldingMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventScheduleV2DtoHoldingMethod = {
  offline: 'offline',
  online: 'online',
  undecided: 'undecided',
} as const;
