/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexTextAllOfGravity = typeof FlexTextAllOfGravity[keyof typeof FlexTextAllOfGravity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexTextAllOfGravity = {
  top: 'top',
  bottom: 'bottom',
  center: 'center',
} as const;
