/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type RichMenuSwitchActionType = typeof RichMenuSwitchActionType[keyof typeof RichMenuSwitchActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RichMenuSwitchActionType = {
  richmenuswitch: 'richmenuswitch',
} as const;
