/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type UserDtoGender = typeof UserDtoGender[keyof typeof UserDtoGender];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDtoGender = {
  male: 'male',
  female: 'female',
  other: 'other',
} as const;
