import { CachedBillingListActionEnum } from '../../enums/action-enum';
import { CachedBillingListAction } from '../../models/action-model';
import { CachedBillingInfo, initialCachedBillingList } from '../hooks/useCachedBillingList';

const cachedBillingListReducer = (
  billingList: CachedBillingInfo,
  action: CachedBillingListAction
): CachedBillingInfo | undefined => {
  if (billingList === undefined) return initialCachedBillingList;

  switch (action.type) {
    case CachedBillingListActionEnum.SET:
      return action.cachedBillingList;

    case CachedBillingListActionEnum.PURGE:
      return initialCachedBillingList;

    default:
      return billingList;
  }
};

export default cachedBillingListReducer;
