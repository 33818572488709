import { isAlphabetOrNumberOrSymbol } from '@atomica.co/utils';
import { z } from 'zod';

export const itemV2Schema = z.object({
  itemCode: z
    .string()
    .min(1, '品目コードを入力してください')
    .max(255, '品目コードは255文字以内で入力してください')
    .refine(value => isAlphabetOrNumberOrSymbol(value), '品目コードは半角英数記号のみ入力できます'),
  itemName: z.string().min(1, '品目名を入力してください').max(255, '品目名は255文字以内で入力してください'),
  unitPrice: z.number(),
  taxId: z.string().uuid('課税を選択してください')
});

export type ItemV2Form = z.infer<typeof itemV2Schema>;
