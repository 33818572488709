export enum BillingDetailScreenNameEnum {
  DETAIL = '請求情報',
  SPACE = '予約利用明細',
  ACCESS = '入退室利用明細',
  LOGS = '履歴'
}

export enum BillingDetailPathEnum {
  DETAIL = 'detail',
  SPACE = 'space-usages',
  ACCESS = 'accesses',
  LOGS = 'logs'
}

export enum BillingAction {
  OPERATE_ALL = 'operate_all',
  OPEN_SELECTED = 'open_selected',
  CONFIRM_AMOUNT = 'confirm_amount',
  DOWNLOAD_CSV = 'download_csv',
  // DOWNLOAD_PDF = 'download_pdf',
  DELETE_ALL = 'delete_all'
}

export enum BillingDetailInputKeyEnum {
  ITEM_NAME = 'itemName',
  UNIT_PRICE = 'unitPrice',
  QUANTITY = 'quantity',
  UNIT = 'unitId',
  TAX = 'taxId',
  TAX_DIV = 'taxDiv',
  TAX_RATE = 'taxRate',
  DISABLED = 'disabled'
}
