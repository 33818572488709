/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchResourceForAdmin200SpaceManagement = typeof FetchResourceForAdmin200SpaceManagement[keyof typeof FetchResourceForAdmin200SpaceManagement];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchResourceForAdmin200SpaceManagement = {
  bitkey: 'bitkey',
  denso: 'denso',
} as const;
