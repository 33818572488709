import { themeV2, themeV3 } from '@atomica.co/components';
import { Index, Text, Title, URL } from '@atomica.co/types';
import React from 'react';
import Linkify from 'react-linkify';
import styled from 'styled-components';
import Screen from '../../components/screen/Screen';
import mojaco from './../../assets/mojaco/mojaco_thanks.png';

interface P {
  title?: Title;
  text?: Text;
  children?: React.ReactNode;
}

const RegisteredScreen: React.FC<P> = React.memo(props => {
  const { title = '登録が完了しました！', text, children } = props;
  return (
    <Screen className='registered-screen'>
      <Container>
        <TitleText>{title}</TitleText>
        <Mojaco src={mojaco} />
        <Linkify
          componentDecorator={(decoratedHref: URL, decoratedText: Text, key: Index) => (
            <a href={decoratedHref} key={key} target='_blank' rel='noreferrer'>
              {decoratedText}
            </a>
          )}
        >
          {text && <Content data-testid='questionnaire-thanks-message'>{text}</Content>}
        </Linkify>
        {children}
      </Container>
    </Screen>
  );
});

RegisteredScreen.displayName = 'RegisteredScreen';
export default RegisteredScreen;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 2.25}px;
  padding: 0 ${themeV2.mixins.v2.spacing * 2}px;

  @supports (height: 1dvh) {
    height: 100dvh;
  }
`;

const TitleText = styled.div`
  ${themeV2.mixins.v2.typography.headLine.small};
  ${themeV3.mixins.v3.color.object.black};
`;

const Content = styled.div`
  ${themeV2.mixins.v2.typography.body.large};
  ${themeV3.mixins.v3.color.object.black};
  white-space: pre-wrap;
`;

const Mojaco = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  user-select: none;
`;
