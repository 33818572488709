/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchBillingsV2ForAdminFilterDate = typeof SearchBillingsV2ForAdminFilterDate[keyof typeof SearchBillingsV2ForAdminFilterDate];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchBillingsV2ForAdminFilterDate = {
  cutoffDate: 'cutoffDate',
  billingDate: 'billingDate',
} as const;
