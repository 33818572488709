import { ShotCategory, ShotSubcategory } from '@atomica.co/irori';
import { Message } from '@atomica.co/types';
import { EMPTY, builder } from '@atomica.co/utils';
import { SafeParseReturnType } from 'zod';
import { aggregateValidation } from '../common';
import { ItemV2Form, itemV2Schema } from './schema';

export const validate = (shotCategories: ShotCategory[]): Message => {
  return aggregateValidation<ItemV2Form>([
    ...validateItems(shotCategories),
    ...validateItems(shotCategories.flatMap(sub => sub.subcategories ?? []))
  ]);
};

const buildItemV2 = (category: ShotCategory | ShotSubcategory) => {
  return builder<ItemV2Form>()
    .itemCode(category.item?.itemCode ?? EMPTY)
    .itemName(category.item?.itemName ?? EMPTY)
    .unitPrice(category.item?.unitPrice ?? 0)
    .taxId(category.item?.tax?.taxId ?? EMPTY)
    .build();
};

const validateItems = (
  shotCategories: ShotCategory[] | ShotSubcategory[]
): SafeParseReturnType<ItemV2Form, ItemV2Form>[] => {
  return shotCategories.map(category => {
    const item = buildItemV2(category);
    return itemV2Schema.safeParse(item);
  });
};
