/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceReservationPaymentsDtoStripeObjectIdType = typeof SpaceReservationPaymentsDtoStripeObjectIdType[keyof typeof SpaceReservationPaymentsDtoStripeObjectIdType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceReservationPaymentsDtoStripeObjectIdType = {
  session: 'session',
  payment_intent: 'payment_intent',
  charge: 'charge',
  customer: 'customer',
  refund: 'refund',
} as const;
