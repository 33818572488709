/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type UpdateResourceReservationPaymentStatusForAdmin200Result = typeof UpdateResourceReservationPaymentStatusForAdmin200Result[keyof typeof UpdateResourceReservationPaymentStatusForAdmin200Result];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateResourceReservationPaymentStatusForAdmin200Result = {
  success: 'success',
  failure: 'failure',
} as const;
