import { Status } from '@atomica.co/components';
import { Questionnaire, QuestionnaireStatus } from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import { isAfter } from 'date-fns';
import { QUESTIONNAIRE_STATUS_LABEL } from '../texts/questionnaire-text';

export const toStatus = (questionnaire: Questionnaire): Status => {
  switch (questionnaire.status) {
    case QuestionnaireStatus.DRAFT:
      return 'default';
    case QuestionnaireStatus.RELEASED:
      if (!questionnaire.answerDueAt) return 'success';
      return isAfter(new Date(), questionnaire.answerDueAt) ? 'default' : 'success';
    case QuestionnaireStatus.PRIVATE:
      return 'warning';
  }
};

export const getQuestionnaireStatusLabel = (questionnaire: Questionnaire): Label => {
  const { status, answerDueAt } = questionnaire;
  switch (status) {
    case QuestionnaireStatus.RELEASED:
      if (!answerDueAt) return QUESTIONNAIRE_STATUS_LABEL[status];
      return isAfter(new Date(), answerDueAt) ? '公開終了' : QUESTIONNAIRE_STATUS_LABEL[status];
    default:
      return QUESTIONNAIRE_STATUS_LABEL[status];
  }
};
