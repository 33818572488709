/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexBoxAllOfAlignItems = typeof FlexBoxAllOfAlignItems[keyof typeof FlexBoxAllOfAlignItems];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexBoxAllOfAlignItems = {
  center: 'center',
  'flex-start': 'flex-start',
  'flex-end': 'flex-end',
} as const;
