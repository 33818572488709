import { Code } from '@atomica.co/types';
import { CustomizationBloomingCamp } from './bloomingCamp';
import { DefaultCustomization } from './default';

type CustomizationType = DefaultCustomization | CustomizationBloomingCamp;

export function createCustomization(baseCode: Code): CustomizationType {
  switch (baseCode) {
    case 'bloomingcamp':
      return new CustomizationBloomingCamp();
    default:
      return new DefaultCustomization();
  }
}
