/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type UserWishDtoStatus = typeof UserWishDtoStatus[keyof typeof UserWishDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserWishDtoStatus = {
  in_progress: 'in_progress',
  done: 'done',
  discarded: 'discarded',
} as const;
