/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchSpaceReservationsForAdminReservationMember = typeof SearchSpaceReservationsForAdminReservationMember[keyof typeof SearchSpaceReservationsForAdminReservationMember];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchSpaceReservationsForAdminReservationMember = {
  all: 'all',
  monthly_member: 'monthly_member',
  drop_in: 'drop_in',
} as const;
