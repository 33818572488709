import { AccessType, Direction } from '@atomica.co/irori';
import { ColorInfo } from '../models/common-model';
import { UsageType } from './base-const';

/** @deprecated */
export const ACCESS_STATUS_COLORS: ColorInfo = {
  [AccessType.IN]: '#ff7165',
  [AccessType.OUT]: '#54d3e3'
};

export const ACCESS_DIRECTION_COLORS: ColorInfo = {
  [Direction.ENTER]: '#ff7165',
  [Direction.EXIT]: '#54d3e3'
};

/** @deprecated */
export const USAGE_TYPE_COLORS: ColorInfo = {
  [UsageType.COWORKING]: '#ff7165',
  [UsageType.ONE_DAY]: '#ff7165',
  [UsageType.ONE_HOUR]: '#ff7165',
  [UsageType.TWO_HOURS]: '#ff7165',
  [UsageType.THREE_HOURS]: '#ff7165',
  [UsageType.CONFERENCE]: '#9dde22',
  [UsageType.EVENT]: '#54d3e3',
  [UsageType.GUEST]: '#34d590',
  [UsageType.PREVIEW]: '#ffa143'
};
