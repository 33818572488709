/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type LocationActionType = typeof LocationActionType[keyof typeof LocationActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocationActionType = {
  location: 'location',
} as const;
