/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type QuestionnaireItemDtoType = typeof QuestionnaireItemDtoType[keyof typeof QuestionnaireItemDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionnaireItemDtoType = {
  radio: 'radio',
  text: 'text',
  checkbox: 'checkbox',
} as const;
