import { WishScope, WishStatus, WishType } from '@atomica.co/irori';
import { Labels } from '../models/common-model';

export const WISH_TYPE_TEXT: Labels = {
  [WishType.WANT]: 'したい',
  [WishType.FAVOR]: 'してほしい'
};

export const WISH_STATUS_TEXT: Labels = {
  [WishStatus.TODO]: '未着手',
  [WishStatus.IN_PROGRESS]: '着手',
  [WishStatus.WAITING]: '確認待ち',
  [WishStatus.DONE]: '完了',
  [WishStatus.DISCARDED]: '破棄'
};

export const WISH_SCOPE_TEXT: Labels = {
  [WishScope.PUBLIC]: '公開',
  [WishScope.BASE]: '拠点内'
};

export const FAILED_TO_REGISTER_WISH = 'Wishの登録に失敗しました。システム管理者にお問い合わせください。';
