/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexTextAllOfAlign = typeof FlexTextAllOfAlign[keyof typeof FlexTextAllOfAlign];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexTextAllOfAlign = {
  start: 'start',
  end: 'end',
  center: 'center',
} as const;
