/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type DeleteEventScheduleV2ForAdminRecurrenceTarget = typeof DeleteEventScheduleV2ForAdminRecurrenceTarget[keyof typeof DeleteEventScheduleV2ForAdminRecurrenceTarget];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteEventScheduleV2ForAdminRecurrenceTarget = {
  thisOne: 'thisOne',
  afterThis: 'afterThis',
} as const;
