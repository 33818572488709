/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchSpaceReservationsForAdminSortCol = typeof SearchSpaceReservationsForAdminSortCol[keyof typeof SearchSpaceReservationsForAdminSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchSpaceReservationsForAdminSortCol = {
  bs_space_reservationscreatedAt: 'bs_space_reservations.createdAt',
  bs_space_reservationsreservationNo: 'bs_space_reservations.reservationNo',
  bs_space_reservationsstartAt: 'bs_space_reservations.startAt',
} as const;
