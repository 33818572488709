/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BillingV2DtoSpacePaymentFrequency = typeof BillingV2DtoSpacePaymentFrequency[keyof typeof BillingV2DtoSpacePaymentFrequency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingV2DtoSpacePaymentFrequency = {
  pay_per_use: 'pay_per_use',
  monthly_payment: 'monthly_payment',
} as const;
