import { Snackbar, SnackbarStatus, useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Message, MilliSecond, Milliseconds } from '@atomica.co/types';
import { EMPTY, noop } from '@atomica.co/utils';
import React from 'react';
import { FC, createContext, useContext } from 'react';

interface P {
  children: React.ReactNode;
}

interface SnackbarProps {
  message: Message;
  status: SnackbarStatus;
  autoHideDuration?: MilliSecond;
}

interface SnackbarContext {
  openSnackbar: (message: Message, status: SnackbarStatus, autoHideDuration?: Milliseconds) => void;
}

const SnackbarContext = createContext<SnackbarContext>({ openSnackbar: noop });

export const useSnackbarV2 = (): SnackbarContext => useContext(SnackbarContext);

const SnackbarProviderV2: FC<P> = React.memo(props => {
  const { children } = props;

  const unmountRef = useUnmountRef();
  const [isOpen, setIsOpen] = useSafeState<boolean>(unmountRef, false);
  const [snackbarProps, setSnackbarProps] = useSafeState<SnackbarProps>(unmountRef, {
    message: EMPTY,
    status: 'success'
  });

  const openSnackbar = useSafeCallback(
    (message: Message, status: SnackbarStatus, autoHideDuration?: Milliseconds): void => {
      setIsOpen(true);
      setSnackbarProps({ message, status, autoHideDuration });
    },
    [setIsOpen, setSnackbarProps]
  );

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      <Snackbar {...snackbarProps} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      {children}
    </SnackbarContext.Provider>
  );
});

SnackbarProviderV2.displayName = 'SnackbarProviderV2';
export default SnackbarProviderV2;
