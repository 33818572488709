/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchContractsV2ForAdminSortCol = typeof SearchContractsV2ForAdminSortCol[keyof typeof SearchContractsV2ForAdminSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchContractsV2ForAdminSortCol = {
  bs_contracts_v2contractNo: 'bs_contracts_v2.contractNo',
  bs_contract_plans_v2contractPlanName: 'bs_contract_plans_v2.contractPlanName',
  bs_contracts_v2contractorName: 'bs_contracts_v2.contractorName',
  bs_contracts_v2startDate: 'bs_contracts_v2.startDate',
  bs_usersfamilyName: 'bs_users.familyName',
  bs_contracts_v2updatedAt: 'bs_contracts_v2.updatedAt',
} as const;
