import { FunctionInfo, toFuncName } from '@atomica.co/irori';
import { RequestType, convert } from '@atomica.co/utils';
import { functions } from '../firebase';

export default class CommonRequest {
  public static call = async <S, T>(info: FunctionInfo, request: S): Promise<T> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(info));

      func(convert(RequestType.SENDER, request))
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => reject(error));
    });
  };
}
