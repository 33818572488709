export enum SearchOption {
  OFFSET = 0,
  LIMIT = 20
}

export enum EventScheduleSearchOption {
  OFFSET = 0,
  LIMIT = 10
}

export enum SearchMode {
  ALL = 'all',
  GROUPED = 'grouped',
  NOT_GROUPED = 'notGrouped'
}

export enum SendingStatus {
  NOTSENT = 'notSent',
  SENT = 'sent'
}

export enum EventSchedulePathEnum {
  SUMMARY = 'summary',
  PARTICIPANTS = 'participants',
  // ANALYSIS ='analysis'
  ENTRY_FORM = 'entry-form-messages',
  REMIND = 'remind-messages'
}

export enum EventScheduleParticipantPathEnum {
  PARTICIPANTS = 'participants',
  GROUPS = 'groups'
}

export enum EventDetailTabEnum {
  SCHEDULES = 'schedules',
  PARTICIPANTS = 'participants'
}

export enum EventScheduleTabEnum {
  SCHEDULED = 0,
  HELD = 1
}

export enum EventScheduleDeadlineEnum {
  UNTIL_END_TIME = 'untilEndTime',
  ASSIGN_DATE = 'assignDate'
}

export enum EventScheduleParticipantStatusEnum {
  INTERESTED = 'isInterested',
  APPLIED = 'isApplied'
}

export enum AssignMethod {
  REVERT = 'revert',
  CHANGE = 'change'
}

export enum AccountEventTabEnum {
  SCHEDULED = 0,
  ATTENDING = 1,
  ATTENDED = 2
}

export enum EventScheduleDeleteMethod {
  THIS = 'this',
  AFTER_ALL = 'afterAll'
}
