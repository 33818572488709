/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchBillingsV2ForAdminSort = typeof SearchBillingsV2ForAdminSort[keyof typeof SearchBillingsV2ForAdminSort];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchBillingsV2ForAdminSort = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;
