/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type SpaceReservationDtoPaymentStatus = typeof SpaceReservationDtoPaymentStatus[keyof typeof SpaceReservationDtoPaymentStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpaceReservationDtoPaymentStatus = {
  payment_in_progress: 'payment_in_progress',
  additional_payment_in_progress: 'additional_payment_in_progress',
  paid: 'paid',
  unpaid: 'unpaid',
} as const;
