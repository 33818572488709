/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type EventParticipantDtoStatus = typeof EventParticipantDtoStatus[keyof typeof EventParticipantDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventParticipantDtoStatus = {
  participate: 'participate',
  maybe: 'maybe',
} as const;
