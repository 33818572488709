/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchBillingsV2ForAdminStatus = typeof SearchBillingsV2ForAdminStatus[keyof typeof SearchBillingsV2ForAdminStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchBillingsV2ForAdminStatus = {
  unconfirmed: 'unconfirmed',
  confirmed: 'confirmed',
  payment_completed: 'payment_completed',
  payment_error: 'payment_error',
  deleted: 'deleted',
} as const;
