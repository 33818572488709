import { useSafeCallback } from '@atomica.co/components';
import { BaseDto, User } from '@atomica.co/irori';
import React, { useEffect } from 'react';
import Screen from '../../components/screen/Screen';
import { auth } from '../../firebase';
import useCachedEmail from '../../redux/hooks/useCachedEmail';
import useCachedInitialAuthorityCode from '../../redux/hooks/useCachedInitialAuthorityCode';
import useCachedJoinURL from '../../redux/hooks/useCachedJoinURL';
import useCachedSpaceReservation from '../../redux/hooks/useCachedSpaceReservation';
import useCachedURL from '../../redux/hooks/useCachedURL';
import usePath from '../../redux/hooks/usePath';
import useProcess from '../../redux/hooks/useProcess';
import { persistor } from '../../redux/store';
import { Path } from '../../router/Routes';

interface P {
  base: BaseDto;
  user: User | undefined;
}

const SignOutScreen: React.FC<P> = React.memo(() => {
  const { openBasePath } = usePath();
  const { hasCachedJoinURL, openCachedJoinURL } = useCachedJoinURL();
  const { clearCachedURL } = useCachedURL();
  const { clearCachedInitialAuthorityCode } = useCachedInitialAuthorityCode();
  const { clearCachedEmail } = useCachedEmail();
  const { clearCachedSpaceReservation } = useCachedSpaceReservation();
  const { clearProcess } = useProcess();
  const { clearCachedJoinURL } = useCachedJoinURL();

  const clearRedux = useSafeCallback((): void => {
    clearCachedEmail();
    clearCachedInitialAuthorityCode();
    clearCachedSpaceReservation();
    clearCachedURL();
    clearProcess();
    clearCachedJoinURL();
  }, [
    clearCachedEmail,
    clearCachedInitialAuthorityCode,
    clearCachedSpaceReservation,
    clearCachedURL,
    clearProcess,
    clearCachedJoinURL
  ]);

  const signOut = useSafeCallback(async (): Promise<void> => {
    await auth.signOut();
    await persistor.purge();
    clearRedux();
    setTimeout(() => openBasePath(Path.SIGN_IN), 2000);
  }, [clearRedux, hasCachedJoinURL, openCachedJoinURL, openBasePath]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <Screen className='sign-out-screen' />;
});

SignOutScreen.displayName = 'SignOutScreen';
export default SignOutScreen;
