/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ImageCarouselTemplateType = typeof ImageCarouselTemplateType[keyof typeof ImageCarouselTemplateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImageCarouselTemplateType = {
  image_carousel: 'image_carousel',
} as const;
