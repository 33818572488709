import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import ErrorMessage from './ErrorMessage';

const NotFound: React.FC<FallbackProps> = React.memo(() => {
  return <ErrorMessage data-testid='not-found' message='このページは存在しません' />;
});

NotFound.displayName = 'NotFound';
export default NotFound;
