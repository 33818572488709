import { Id } from '@atomica.co/types';
import { EnvDef, uuid } from '@atomica.co/utils';
import { AxiosRequestConfig } from 'axios';
import querystring from 'querystring';
import env from '../env/env';

export const IRORI_CLIENT_ID = 1656282585;

export const IRORI_CLIENT_SECRET = 'f8f2138f8e47eb250a13131331eb0055';

export const NONCE = '09876xyz';

export const LINE_AUTHORIZE_URL = 'https://access.line.me/oauth2/v2.1/authorize';

export const LINE_AUTHORIZE_PARAMS: querystring.ParsedUrlQueryInput = {
  response_type: 'code',
  client_id: IRORI_CLIENT_ID,
  state: uuid(),
  scope: 'profile openid email',
  nonce: NONCE,
  prompt: 'consent',
  max_age: 120,
  bot_prompt: 'normal'
};

export const LINE_TOKEN_URL = 'https://api.line.me/oauth2/v2.1/token';

export const LINE_TOKEN_CONFIG: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
};

let defaultLiffId: Id;

const DEVELOPMENT_LIFF_ID = '1656282585-DMEXOZPX';

const STAGING_LIFF_ID = '1656282585-BRWOeqpO';

const PRODUCTION_LIFF_ID = '1656282585-nMV8O2E8';

switch (String(env)) {
  case EnvDef.LOCAL:
  case EnvDef.TEST:
  case EnvDef.DEVELOPMENT:
    defaultLiffId = DEVELOPMENT_LIFF_ID;
    break;

  case EnvDef.STAGING:
    defaultLiffId = STAGING_LIFF_ID;
    break;

  case EnvDef.PRODUCTION:
    defaultLiffId = PRODUCTION_LIFF_ID;
    break;

  default:
    throw new Error(`${env} is out of target.`);
}

export const toLiffId = (liffId?: Id): Id => {
  return liffId ?? defaultLiffId;
};
