/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchResourcesForAdminPublish = typeof SearchResourcesForAdminPublish[keyof typeof SearchResourcesForAdminPublish];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchResourcesForAdminPublish = {
  open: 'open',
  authority_limited: 'authority_limited',
  contract_limited: 'contract_limited',
} as const;
