/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchBillingsV2ForAdminSortCol = typeof SearchBillingsV2ForAdminSortCol[keyof typeof SearchBillingsV2ForAdminSortCol];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchBillingsV2ForAdminSortCol = {
  bs_billings_v2billingStatus: 'bs_billings_v2.billingStatus',
  bs_billings_v2billingNo: 'bs_billings_v2.billingNo',
  bs_billings_v2contractorName: 'bs_billings_v2.contractorName',
  bs_billings_v2billingName: 'bs_billings_v2.billingName',
  bs_billings_v2taxIncludedTotalPrice: 'bs_billings_v2.taxIncludedTotalPrice',
  bs_billings_v2cutoffDate: 'bs_billings_v2.cutoffDate',
  bs_billings_v2billingDate: 'bs_billings_v2.billingDate',
  bs_billings_v2updatedAt: 'bs_billings_v2.updatedAt',
  bs_contracts_v2entityName: 'bs_contracts_v2.entityName',
} as const;
