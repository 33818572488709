import { ItemV2 } from '@atomica.co/irori';
import { Code, Rate } from '@atomica.co/types';
import { EMPTY, ZERO, isGreaterThanZero, isZero } from '@atomica.co/utils';
import { UsedContractOptionEnum } from '../enums/contract-v2-enum';
import { ValidateTypeEnum } from '../enums/validate-enum';
import { ConfigValidateColumn, ValidateMessage, validate } from './validate-util';

export const getItemCode = (item: ItemV2 | undefined): Code => {
  return !!item && !!item.itemCode ? item.itemCode : EMPTY;
};

export const hasUnitPrice = (item: ItemV2 | undefined): boolean => {
  return !!item && (isGreaterThanZero(item.unitPrice) || isZero(item.unitPrice));
};

export const getTaxRate = (item: ItemV2 | undefined): Rate => {
  if (!item || !item.tax) return ZERO;
  return isGreaterThanZero(item.tax.taxRate) ? item.tax.taxRate : ZERO;
};

export const isBasicCharge = (itemCode: Code | undefined): boolean => {
  return !!itemCode && itemCode === UsedContractOptionEnum.BASIC_CHARGE;
};

export const CONFIG_VALIDATE_ITEM_V2: ConfigValidateColumn[] = [
  { columnName: 'itemCode', itemName: '品目コード', validateType: ValidateTypeEnum.ALPHABET_NUMBER_SYMBOL },
  { columnName: 'itemName', itemName: '品目名', validateType: ValidateTypeEnum.EMPTY }
];

export const itemValidator = (item: ItemV2): ValidateMessage => {
  const configs: ConfigValidateColumn[] = [];

  configs.push(...CONFIG_VALIDATE_ITEM_V2);

  return validate<ItemV2>(item, configs);
};
