/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BaseLoginServicesDtoLoginServiceType = typeof BaseLoginServicesDtoLoginServiceType[keyof typeof BaseLoginServicesDtoLoginServiceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseLoginServicesDtoLoginServiceType = {
  mail: 'mail',
  google: 'google',
  line: 'line',
  sakura: 'sakura',
} as const;
