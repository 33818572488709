import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import { Email } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { toCachedInvitationEmailAction } from '../actions/cached-invitation-email-action';
import store from '../store';

interface P {
  popCachedInvitationEmail: () => Email | undefined;
  saveCachedInvitationEmail: (email: Email | undefined) => void;
  clearCachedInvitationEmail: () => void;
}

function useCachedInvitationEmail(): P {
  const unmountRef = useUnmountRef();
  const [cachedInvitationEmail, setCachedInvitationEmail] = useSafeState<Email | undefined>(
    unmountRef,
    store.getState().cachedInvitationEmail
  );

  const saveCachedInvitationEmail = useSafeCallback(
    (email: Email | undefined): void => {
      setCachedInvitationEmail(email);
      const action = toCachedInvitationEmailAction(email);
      store.dispatch(action);
    },
    [setCachedInvitationEmail]
  );

  const clearCachedInvitationEmail = useSafeCallback((): void => {
    setCachedInvitationEmail(EMPTY);
    const action = toCachedInvitationEmailAction(EMPTY);
    store.dispatch(action);
  }, [setCachedInvitationEmail]);

  const popCachedInvitationEmail = useSafeCallback((): Email | undefined => {
    const cachedInvitationEmailToReturn = cachedInvitationEmail;
    return cachedInvitationEmailToReturn;
  }, [cachedInvitationEmail]);

  return {
    popCachedInvitationEmail,
    saveCachedInvitationEmail,
    clearCachedInvitationEmail
  };
}

export default useCachedInvitationEmail;
