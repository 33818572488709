/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexButtonAllOfHeight = typeof FlexButtonAllOfHeight[keyof typeof FlexButtonAllOfHeight];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexButtonAllOfHeight = {
  md: 'md',
  sm: 'sm',
} as const;
