import { Component, HEADER_HEIGHT, theme } from '@atomica.co/components';
import { Message } from '@atomica.co/types';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import mojaco from './../../assets/mojaco/mojaco_sorry.png';

interface P {
  message: Message;
  'data-testid'?: string;
}

const ErrorMessage: React.FC<P> = React.memo(props => {
  const { message } = props;

  return (
    <Component className='error-message'>
      <Container data-testid={props['data-testid']}>
        <Image src={mojaco} />

        <StyledMessage>{message}</StyledMessage>
      </Container>
    </Component>
  );
});

ErrorMessage.displayName = 'ErrorMessage';
export default ErrorMessage;

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: ${theme.mixins.spacing * 3}px;
`;

const StyledMessage = styled(Typography)`
  width: 100%;
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.twenty}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: Mamelon, ${theme.mixins.typography.fontFamily};
  text-align: center;
  white-space: pre-line;
`;
