import { Component, themeV2 } from '@atomica.co/components';
import { Typography } from '@material-ui/core';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../constants/common-const';
import mojaco from './../../assets/mojaco/mojaco_sorry.png';

const NoPrivilege: React.FC<FallbackProps> = React.memo(() => {
  return (
    <Component className='no-privilege'>
      <Container>
        <Image src={mojaco} />

        <Message>アクセスする権限がありません</Message>
      </Container>
    </Component>
  );
});

NoPrivilege.displayName = 'NoPrivilege';
export default NoPrivilege;

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  @supports (height: 1dvh) {
    height: calc(100dvh - ${HEADER_HEIGHT}px);
  }
`;

const Image = styled.img`
  width: 400px;
  height: 400px;
  margin: ${themeV2.mixins.spacing * 3}px;
`;

const Message = styled(Typography)`
  ${themeV2.mixins.v2.typography.title.xLarge}
  font-family: Mamelon;
  color: ${themeV2.mixins.v2.color.font.gray};
`;
