/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type MessageV2DtoStatus = typeof MessageV2DtoStatus[keyof typeof MessageV2DtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageV2DtoStatus = {
  suspended: 'suspended',
  waiting: 'waiting',
  completed: 'completed',
  retry: 'retry',
  error: 'error',
  not_sent: 'not_sent',
} as const;
