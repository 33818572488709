import { Component, styleForFullExpansion, themeV2 } from '@atomica.co/components';
import { BaseDto, User } from '@atomica.co/irori';
import React from 'react';
import styled from 'styled-components';
import IntroductionSection from './section/IntroductionSection';
import MessageSection from './section/MessageSection';
import ProfileSectionV2 from './section/ProfileSectionV2';
import WishSection from './section/WishSection';

interface P {
  base: BaseDto;
  selectedUser: User | undefined;
  signInUser: User | undefined;
  isLoaderShown?: boolean;
  initialize?: () => Promise<void>;
}

const UserOverviewV2: React.FC<P> = React.memo(props => {
  const { base, isLoaderShown = false, selectedUser, signInUser, initialize } = props;

  return (
    <Component className='user-overview' loading={isLoaderShown} style={styleForFullExpansion}>
      {selectedUser && signInUser && (
        <Container>
          <ProfileSectionV2 selectedUser={selectedUser} base={base} initialize={initialize} />
          <WishSection base={base} selectedUser={selectedUser} signInUser={signInUser} />
          <BelowWrapper>
            <IntroductionSection base={base} selectedUser={selectedUser} signInUser={signInUser} />
            <MessageSection base={base} selectedUser={selectedUser} signInUser={signInUser} />
          </BelowWrapper>
        </Container>
      )}
    </Component>
  );
});

UserOverviewV2.displayName = 'UserOverviewV2';
export default UserOverviewV2;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  margin-top: ${themeV2.mixins.v2.spacing * 2}px;
  padding-bottom: ${themeV2.mixins.v2.spacing * 3}px;
`;

const BelowWrapper = styled.div`
  gap: ${themeV2.mixins.v2.spacing * 3}px;
  display: flex;
`;
