/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type UserDtoCategory = typeof UserDtoCategory[keyof typeof UserDtoCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDtoCategory = {
  other: 'other',
  work: 'work',
  university: 'university',
  high_school: 'high_school',
  prefectural_servant: 'prefectural_servant',
  city_office_servant: 'city_office_servant',
  other_servant: 'other_servant',
} as const;
