import { AccessType, Direction } from '@atomica.co/irori';
import { Labels } from '../models/common-model';

/** @deprecated */
export const ACCESS_LABELS: Labels = {
  [AccessType.IN]: '入室',
  [AccessType.OUT]: '退室'
};

export const ACCESS_DIRECTION_LABELS: Labels = {
  [Direction.ENTER]: '入室',
  [Direction.EXIT]: '退室'
};
