/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractDetailV2DtoRecurrencePattern = typeof ContractDetailV2DtoRecurrencePattern[keyof typeof ContractDetailV2DtoRecurrencePattern];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractDetailV2DtoRecurrencePattern = {
  at_once: 'at_once',
  monthly: 'monthly',
} as const;
