import { CachedContractListActionEnum } from '../../enums/action-enum';
import { CachedContractListAction } from '../../models/action-model';
import { CachedSearchContractInfo } from '../hooks/useCachedContractList';

export const toContractListAction = (cachedContractList: CachedSearchContractInfo): CachedContractListAction => {
  return {
    type: CachedContractListActionEnum.SET,
    cachedContractList
  };
};
