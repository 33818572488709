/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type BillingDetailV2DtoCategory = typeof BillingDetailV2DtoCategory[keyof typeof BillingDetailV2DtoCategory];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillingDetailV2DtoCategory = {
  at_once: 'at_once',
  discount: 'discount',
  monthly: 'monthly',
  manual: 'manual',
  space_usage: 'space_usage',
  cancel_fee: 'cancel_fee',
  access: 'access',
} as const;
