/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchWishesForAdminTypesItem = typeof SearchWishesForAdminTypesItem[keyof typeof SearchWishesForAdminTypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchWishesForAdminTypesItem = {
  favor: 'favor',
  want: 'want',
} as const;
