import { CachedQuestionnaireActionEnum } from '../../enums/action-enum';
import { CachedQuestionnaireAction } from '../../models/action-model';
import { CachedQuestionnaireInfo } from '../hooks/useQuestionnaire';

export const toQuestionnaireAction = (cachedQuestionnaireInfo?: CachedQuestionnaireInfo): CachedQuestionnaireAction => {
  return {
    type: CachedQuestionnaireActionEnum.SET,
    cachedQuestionnaireInfo
  };
};
