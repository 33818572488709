import { SpaceAvailability, SpaceReservation, User } from '@atomica.co/irori';
import { TimeZone } from '@atomica.co/types';
import { hasLength, toZonedTimeFromUtc } from '@atomica.co/utils';
import { addDays, format, getDay, getWeekOfMonth } from 'date-fns';

export interface SpaceAvailabilityAt {
  spaceStartAt: Date | undefined;
  spaceEndAt: Date | undefined;
}

export const toZonedTimeFromUtcInBrowser = (date: Date, timezone: TimeZone): Date | undefined => {
  if (!date) return;
  return toZonedTimeFromUtc(
    new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes())),
    timezone
  );
};

export const getSpaceAvailabilityAtOfTargetDate = (
  targetDate: Date,
  timezone: TimeZone,
  availabilities?: SpaceAvailability[]
): SpaceAvailabilityAt => {
  const availabilityAt = { spaceStartAt: undefined, spaceEndAt: undefined };
  if (!hasLength(availabilities) || !targetDate) return availabilityAt;

  const targetWeekday = getDay(targetDate);
  const targetWeek = getWeekOfMonth(targetDate);
  const availability = availabilities.find(
    (availability: SpaceAvailability) =>
      (availability.weeks === 0 || availability.weeks === targetWeek) && availability.dayOfWeek === targetWeekday
  );
  if (!availability) return availabilityAt;
  const formatedTargetDate = format(targetDate, 'yyyy/MM/dd');
  const startAt = new Date(`${formatedTargetDate} ${availability.startAt}`);
  const spaceStartAt = toZonedTimeFromUtcInBrowser(startAt, timezone);
  const endAt = new Date(`${formatedTargetDate} ${availability.endAt}`);
  const spaceEndAt =
    availability.startAt === availability.endAt
      ? addDays(spaceStartAt!, 1)
      : toZonedTimeFromUtcInBrowser(endAt, timezone);
  return { spaceStartAt, spaceEndAt };
};

export const isReferableReservation = (reservation: SpaceReservation | undefined, user: User | undefined): boolean => {
  if (!user || !reservation) return false;
  return (
    reservation.createdUser?.userId === user.userId ||
    reservation.participants!.some(participant => participant.user?.userId === user.userId)
  );
};
