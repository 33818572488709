import { Path } from '../router/Routes';

export const EVENE_SCHEDULE_DETAIL_PATHS: Path[] = [
  Path.EVENT_SCHEDULE_V2_DETAILS,
  Path.EVENT_SCHEDULE_PARTICIPANTS_V2,
  //   Path.EVENT_SCHEDULE_GROUPS_V2,
  Path.ENTRY_FORM_MESSAGES,
  Path.REMIND_MESSAGES
];

export const EVENT_NAME = 'eventName';
export const EVENT_DESCRIPTION = 'eventDescription';
export const EVENT_PHOTO_URL = 'eventPhotoURL';
export const EVENT_CREATED_AT = 'eventCreatedAt';
export const EVENT_UPDATED_AT = 'eventUpdatedAt';

export const EVENT_SCHEDULE_NAME = 'eventScheduleName';
export const EVENT_SCHEDULE_DESCRIPTION = 'eventScheduleDescription';
export const EVENT_SCHEDULE_DEADLINE_AT = 'deadlineAtV2';
export const EVENT_SCHEDULE_START_AT = 'startAtV2';
export const EVENT_SCHEDULE_END_AT = 'endAtV2';
export const EVENT_SCHEDULE_CREATED_AT = 'eventScheduleCreatedAt';
export const EVENT_SCHEDULE_UPDATED_AT = 'eventScheduleUpdatedAt';

export const PARTICIPANT_NAME = 'participantName';
export const PARTICIPANT_GENDER = 'participantGender';
export const PARTICIPANT_DATE_OF_BIRTH = 'participantDateOfBirth';
export const PARTICIPANT_PHONE = 'participantPhone';
export const PARTICIPANT_EMAIL = 'participantEmail';
export const PARTICIPANT_COMPANY_NAME = 'participantCompanyName';
export const PARTICIPANT_POSTAL_CODE = 'participantPostalCode';
export const PARTICIPANT_ADDRESS = 'participantAddress';
export const PARTICIPANT_CATEGOPRY = 'participantCategory';
export const PARTICIPANT_INFLOW_SOURCE = 'participantInflowSource';
export const PARTICIPANT_PHOTO_URL = 'participantPhotoURL';
export const GROUP_NAME = 'groupName';
export const PARTICIPANT_CREATED_AT = 'participantCreatedAt';
export const PARTICIPANT_UPDATED_AT = 'participantUpdatedAt';
