/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type FlexButtonAllOfStyle = typeof FlexButtonAllOfStyle[keyof typeof FlexButtonAllOfStyle];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FlexButtonAllOfStyle = {
  primary: 'primary',
  secondary: 'secondary',
  link: 'link',
} as const;
