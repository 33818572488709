import { useSafeCallback } from '@atomica.co/components';
import { FunctionInfo, toFuncName } from '@atomica.co/irori';
import { RequestType, convert } from '@atomica.co/utils';
import { useErrorBoundary } from 'react-error-boundary';
import { functions } from '../../firebase';

interface P {
  commonRequest: <S, T>(info: FunctionInfo, request: S) => Promise<T>;
}

const useCommonRequest = (): P => {
  const { showBoundary } = useErrorBoundary();

  const commonRequest = useSafeCallback(async <S, T>(info: FunctionInfo, request: S): Promise<T> => {
    return await new Promise((resolve, reject) => {
      const func = functions.httpsCallable(toFuncName(info));

      func(convert(RequestType.SENDER, request))
        .then(res => resolve(convert(RequestType.RECEIVER, res.data)))
        .catch(error => {
          showBoundary(error);
          reject(error);
        });
    });
  }, []);

  return {
    commonRequest
  };
};

export default useCommonRequest;
