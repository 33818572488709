import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import {
  FETCH_USER_STUDENT_DIVS,
  FetchUserStudentDivsRequest,
  FetchUserStudentDivsResponse,
  UserStudentDiv
} from '@atomica.co/irori';
import { Code } from '@atomica.co/types';
import { builder } from '@atomica.co/utils';
import useCommonRequest from './useCommonRequest';

interface P {
  userStudentDivs: UserStudentDiv[];
  loadUserStudentDivs: (baseCode: Code) => Promise<void>;
}

function useUserStudentDiv(): P {
  const { commonRequest } = useCommonRequest();
  const unmountRef = useUnmountRef();
  const [userStudentDivs, setUserStudentDivs] = useSafeState<UserStudentDiv[]>(unmountRef, []);

  const loadUserStudentDivs = useSafeCallback(
    async (baseCode: Code): Promise<void> => {
      const request = builder<FetchUserStudentDivsRequest>().baseCode(baseCode).build();
      const response = await commonRequest<FetchUserStudentDivsRequest, FetchUserStudentDivsResponse>(
        FETCH_USER_STUDENT_DIVS,
        request
      );
      setUserStudentDivs(response.userStudentDivs);
    },
    [commonRequest, setUserStudentDivs]
  );

  return {
    userStudentDivs,
    loadUserStudentDivs
  };
}

export default useUserStudentDiv;
