import { PrefectureWithCities } from '@atomica.co/irori';
import { City } from '@atomica.co/types';
import { PREFECTURE_NAMES, Prefecture } from '@atomica.co/utils';

export const findSpecificCities = (
  prefectureWithCities: PrefectureWithCities[],
  prefecture: Prefecture | undefined
): City[] => {
  if (!prefecture) return [];
  return prefectureWithCities.find(p => p.prefecture === PREFECTURE_NAMES[prefecture])?.cities ?? [];
};
