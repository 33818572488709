/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SaveEventScheduleV2ForAdminBodyRecurrenceTarget = typeof SaveEventScheduleV2ForAdminBodyRecurrenceTarget[keyof typeof SaveEventScheduleV2ForAdminBodyRecurrenceTarget];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaveEventScheduleV2ForAdminBodyRecurrenceTarget = {
  thisOne: 'thisOne',
  afterThis: 'afterThis',
} as const;
