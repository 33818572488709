import { ButtonV2, Component, themeV2 } from '@atomica.co/components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import styled from 'styled-components';
import mojaco_bow from './../../../assets/mojaco/mojaco_bow.png';

const CompleteRegisterCreditCardScreen: React.FC = React.memo(() => {
  return (
    <Component className='register-contract-screen' style={styleForComponent}>
      <Container>
        <Wrapper>
          <Mojaco src={mojaco_bow} />
          <TitleText>{'クレジットカードの登録が\n完了しました'}</TitleText>
          <ButtonV2 onClick={() => window.close()} label='閉じる' size='large' />
        </Wrapper>
      </Container>
    </Component>
  );
});
CompleteRegisterCreditCardScreen.displayName = 'CompleteRegisterCreditCardScreen';
export default CompleteRegisterCreditCardScreen;

const styleForComponent: CSSProperties = {
  height: '100%'
};

const Container = styled.div`
  display: flex;
  padding: 48px 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Mojaco = styled.img`
  display: flex;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled.div`
  color: ${themeV2.mixins.v2.color.font.black};
  ${themeV2.mixins.v2.typography.headLine.small};
  text-align: center;
  white-space: pre-wrap;
`;
