import { ButtonV2, Component, themeV2 } from '@atomica.co/components';
import { Message } from '@atomica.co/types';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

interface P extends RouteComponentProps {
  messageText: Message;
  messageSubText?: Message;
  openHome: () => void;
}
const Joined: React.FC<P> = React.memo(props => {
  const { messageText, messageSubText, openHome } = props;

  return (
    <Component className='valid-invitation'>
      <Card>
        <MessageSection>
          <MessageText>{messageText}</MessageText>
          {messageSubText && <MessageSubText>{messageSubText}</MessageSubText>}
        </MessageSection>
        <HomeSection>
          <MessageSubText>さっそくHOMEを開いてみましょう！</MessageSubText>
          <ButtonV2 type='primary' isFullWidth label='HOME' onClick={openHome} />
        </HomeSection>
      </Card>
    </Component>
  );
});

Joined.displayName = 'Joined';
export default Joined;

const Card = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 16px;
  padding: ${themeV2.mixins.v2.spacing * 4}px ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;
const HomeSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  align-items: center;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
`;

const MessageSection = styled.div`
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
  text-align: center;
`;
const MessageText = styled.p`
  ${themeV2.mixins.v2.typography.label.large};
  white-space: pre-line;
`;
const MessageSubText = styled.p`
  ${themeV2.mixins.v2.typography.body.medium};
  white-space: pre-line;
`;
