/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type ContractDtoPaymentMethod = typeof ContractDtoPaymentMethod[keyof typeof ContractDtoPaymentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractDtoPaymentMethod = {
  credit_card: 'credit_card',
  bank_transfer: 'bank_transfer',
  cash: 'cash',
} as const;
