import { CachedDataForSaveSpaceReservationAutomaticallyActionEnum } from '../../enums/action-enum';
import { CachedDataForSaveSpaceReservationAutomaticallyAction } from '../../models/action-model';
import {
  CachedDataForSaveSpaceReservationAutomatically,
  initialCachedDataForSaveSpaceReservationAutomatically
} from '../hooks/useCachedDataForSaveSpaceReservationAutomatically';

const cachedDataForSaveSpaceReservationAutomaticallyReducer = (
  dataForSaveSpaceReservationAutomatically: CachedDataForSaveSpaceReservationAutomatically,
  action: CachedDataForSaveSpaceReservationAutomaticallyAction
): CachedDataForSaveSpaceReservationAutomatically | undefined => {
  if (dataForSaveSpaceReservationAutomatically === undefined)
    return initialCachedDataForSaveSpaceReservationAutomatically;

  switch (action.type) {
    case CachedDataForSaveSpaceReservationAutomaticallyActionEnum.SET:
      return action.cachedDataForSaveSpaceReservationAutomatically;

    case CachedDataForSaveSpaceReservationAutomaticallyActionEnum.PURGE:
      return initialCachedDataForSaveSpaceReservationAutomatically;

    default:
      return dataForSaveSpaceReservationAutomatically;
  }
};

export default cachedDataForSaveSpaceReservationAutomaticallyReducer;
