/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type FetchQuestionnaireForAdminStatusesItem = typeof FetchQuestionnaireForAdminStatusesItem[keyof typeof FetchQuestionnaireForAdminStatusesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FetchQuestionnaireForAdminStatusesItem = {
  draft: 'draft',
  private: 'private',
  released: 'released',
} as const;
