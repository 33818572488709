import { Email } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { CachedEmailActionEnum } from '../../enums/action-enum';
import { CachedEmailAction } from '../../models/action-model';

const cachedEmailReducer = (cachedEmail: Email, action: CachedEmailAction): Email | undefined => {
  if (cachedEmail === undefined) return EMPTY;

  switch (action.type) {
    case CachedEmailActionEnum.SET:
      return action.cachedEmail;

    case CachedEmailActionEnum.PURGE:
      return EMPTY;

    default:
      return cachedEmail;
  }
};

export default cachedEmailReducer;
