import { URL } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { CachedJoinURLActionEnum } from '../../enums/action-enum';
import { CachedJoinURLAction } from '../../models/action-model';

const cachedJoinURLReducer = (cachedJoinURL: URL, action: CachedJoinURLAction): URL | undefined => {
  if (cachedJoinURL === undefined) return EMPTY;

  switch (action.type) {
    case CachedJoinURLActionEnum.SET:
      return action.cachedJoinURL;

    case CachedJoinURLActionEnum.PURGE:
      return EMPTY;

    default:
      return cachedJoinURL;
  }
};

export default cachedJoinURLReducer;
