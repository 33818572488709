import { ButtonV2, Component, LinkButton, themeV2 } from '@atomica.co/components';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

interface P extends RouteComponentProps {
  openSignIn: () => void;
  openHome: () => void;
  isSigned: boolean;
}

const InvalidInvitation: React.FC<P> = React.memo(props => {
  const { openHome, openSignIn, isSigned } = props;

  return (
    <Component className='invalid-invitation'>
      <Card>
        <MessageSection>
          <MessageText> 無効なリクエストです </MessageText>
          <MessageSubText>
            すでに招待に参加済み、または招待が無効になっているため、このリンクを利用することができません。
          </MessageSubText>
        </MessageSection>

        <LinkSection>
          {!isSigned && <LinkButton size='small' label='すでにアカウントをお持ちの方はログイン' onClick={openSignIn} />}

          {isSigned && <ButtonV2 type='primary' isFullWidth label='HOMEに戻る' onClick={openHome} />}
        </LinkSection>
      </Card>
    </Component>
  );
});

InvalidInvitation.displayName = 'InvalidInvitation';
export default InvalidInvitation;

const Card = styled.div`
  width: 100%;
  height: auto;
  background: ${themeV2.mixins.v2.color.background.white};
  border-radius: 16px;
  padding: ${themeV2.mixins.v2.spacing * 4}px ${themeV2.mixins.v2.spacing * 2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${themeV2.mixins.v2.spacing * 3}px;
`;

const MessageSection = styled.div`
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
  text-align: center;
`;
const MessageText = styled.p`
  ${themeV2.mixins.v2.typography.label.large};
  white-space: pre-line;
`;
const MessageSubText = styled.p`
  ${themeV2.mixins.v2.typography.body.medium};
  white-space: pre-line;
`;
const LinkSection = styled.div`
  width: 100%;
  padding: 0 ${themeV2.mixins.v2.spacing * 3}px;
  margin-top: ${themeV2.mixins.v2.spacing * 5}px;
  display: flex;
  justify-content: center;
  && {
    color: ${themeV2.mixins.v2.color.font.blue} !important;
  }
`;
