import { ShotQuantityInputType } from '@atomica.co/irori';
import { Label } from '@atomica.co/types';
import { CoBaTakamatsuShotPaymentMethod, ShotPaymentMethod } from '../enums/shot-enum';
import { Labels } from '../models/common-model';

export const NUMBER_OF_PEOPLE_LABEL_ONE_DAY = '1DAY利用人数';

export const PAYMENT_METHOD_LABELS: Labels = {
  [ShotPaymentMethod.CASH]: '現金',
  [ShotPaymentMethod.CREDIT_CARD]: 'クレジットカード',
  [ShotPaymentMethod.E_MONEY]: '電子マネー',
  [ShotPaymentMethod.QR]: 'QRコード',
  [ShotPaymentMethod.ID]: 'iD',
  [ShotPaymentMethod.QUICK_PAY]: 'QuickPay',
  [ShotPaymentMethod.OTHERS]: 'その他',
  [CoBaTakamatsuShotPaymentMethod.PAY_PAY]: 'PayPay'
};

export const SHOT_QUANTITY_INPUT_TYPE_LABELS: Record<ShotQuantityInputType, Label> = {
  [ShotQuantityInputType.MANUAL]: 'する',
  [ShotQuantityInputType.FIXED]: 'しない'
};
