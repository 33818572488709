import React, { useEffect, useMemo } from 'react';
import { isAuthGuarded as guarded } from '../../converters/path-converter';
import usePath from '../../redux/hooks/usePath';
import GuardedScreens from './guarded-screens/GuardedScreens';
import NoGuardedScreens from './no-guarded-screens/NoGuardedScreens';

const Root: React.FC = React.memo(() => {
  const { path, addBrowserBackEventListener, removeBrowserBackEventListener } = usePath();
  const isAuthGuard = useMemo<boolean>(() => guarded(path), [path]);

  useEffect(() => {
    addBrowserBackEventListener();
    return () => removeBrowserBackEventListener();
  }, [addBrowserBackEventListener, removeBrowserBackEventListener]);

  return <>{isAuthGuard ? <GuardedScreens /> : <NoGuardedScreens />}</>;
});

Root.displayName = 'Root';
export default Root;
