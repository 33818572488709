/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type CustomerDtoCustomerType = typeof CustomerDtoCustomerType[keyof typeof CustomerDtoCustomerType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerDtoCustomerType = {
  corporate: 'corporate',
  person: 'person',
} as const;
