import { useSafeCallback, useSafeState, useUnmountRef } from '@atomica.co/components';
import store from '../store';
import { Code } from '@atomica.co/types';
import { toCachedInitialAuthorityCodeAction } from '../actions/cached-initial-authority-code-action';
import { EMPTY } from '@atomica.co/utils';

interface P {
  cachedInitialAuthorityCode: Code;
  clearCachedInitialAuthorityCode: () => void;
  saveCachedInitialAuthorityCode: (authorityCode: Code) => void;
}

function useCachedInitialAuthorityCode(): P {
  const unmountRef = useUnmountRef();
  const [cachedInitialAuthorityCode, setCachedInitialAuthorityCode] = useSafeState<Code>(
    unmountRef,
    store.getState().cachedInitialAuthorityCode
  );

  const saveCachedInitialAuthorityCode = useSafeCallback(
    (authorityCode: Code): void => {
      setCachedInitialAuthorityCode(authorityCode);
      const action = toCachedInitialAuthorityCodeAction(authorityCode);
      store.dispatch(action);
    },
    [setCachedInitialAuthorityCode]
  );

  const clearCachedInitialAuthorityCode = useSafeCallback((): void => {
    setCachedInitialAuthorityCode(EMPTY);
    const action = toCachedInitialAuthorityCodeAction(EMPTY);
    store.dispatch(action);
  }, [setCachedInitialAuthorityCode]);

  return {
    cachedInitialAuthorityCode,
    clearCachedInitialAuthorityCode,
    saveCachedInitialAuthorityCode
  } as P;
}

export default useCachedInitialAuthorityCode;
