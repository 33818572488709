import {
  EventScheduleParticipantId,
  EventScheduleParticipantV2,
  EventScheduleGroupV2,
  EventScheduleV2,
  User
} from '@atomica.co/irori';
import { builder } from '@atomica.co/utils';

export const toEventScheduleParticipantV2 = (
  eventScheduleParticipantId: EventScheduleParticipantId,
  eventSchedule: EventScheduleV2,
  user: User,
  eventGroup: EventScheduleGroupV2
): EventScheduleParticipantV2 => {
  return builder<EventScheduleParticipantV2>()
    .eventScheduleParticipantId(eventScheduleParticipantId)
    .eventSchedule(eventSchedule)
    .user(user)
    .eventGroup(eventGroup)
    .build();
};
