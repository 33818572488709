import { URL } from '@atomica.co/types';
import { EMPTY } from '@atomica.co/utils';
import { CachedURLActionEnum } from '../../enums/action-enum';
import { CachedURLAction } from '../../models/action-model';

const cachedURLReducer = (cachedURL: URL, action: CachedURLAction): URL | undefined => {
  if (cachedURL === undefined) return EMPTY;

  switch (action.type) {
    case CachedURLActionEnum.SET:
      return action.cachedURL;

    case CachedURLActionEnum.PURGE:
      return EMPTY;

    default:
      return cachedURL;
  }
};

export default cachedURLReducer;
