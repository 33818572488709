/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type SearchQuestionnaireForAdminStatus = typeof SearchQuestionnaireForAdminStatus[keyof typeof SearchQuestionnaireForAdminStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchQuestionnaireForAdminStatus = {
  all: 'all',
  draft: 'draft',
  released: 'released',
  private: 'private',
  expired: 'expired',
} as const;
