import { Component, theme } from '@atomica.co/components';
import { Contract } from '@atomica.co/irori';
import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface P {
  contract: Contract;
}

const ContractRow: React.FC<P> = React.memo(props => {
  const { contract } = props;

  return (
    <Component className='contract-row'>
      <Container>
        <Column>{contract.contractName}</Column>

        <Column>{contract.representativeName}</Column>
      </Container>
    </Component>
  );
});

ContractRow.displayName = 'ContractRow'
export default ContractRow;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
`;

const Column = styled(Typography)`
  width: calc(100% / 2);
  height: auto;
  color: ${theme.mixins.typography.fontColor.gray};
  font-size: ${theme.mixins.typography.fontSize.sixteen}px;
  font-weight: ${theme.mixins.typography.fontWeight.fourHundreds};
  font-family: ${theme.mixins.typography.fontFamily};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;
