/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * パブリックapi
 * OpenAPI spec version: 1.0.0
 */

export type PostbackActionType = typeof PostbackActionType[keyof typeof PostbackActionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostbackActionType = {
  postback: 'postback',
} as const;
