/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * irori api
 * admin認証を行うapi
 * OpenAPI spec version: 1.0.0
 */

export type AccessV2DtoAction = typeof AccessV2DtoAction[keyof typeof AccessV2DtoAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessV2DtoAction = {
  unlock: 'unlock',
  lock: 'lock',
} as const;
